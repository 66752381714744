import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IAppState } from '../../../types/state';
import { UiService } from '../../../services/ui/ui.service';
import { PatientFacilitiesVisit } from 'app/types/models';
import { CiamUser, CiamAuth, GraphQLRequest } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

const GET_FACILITY_VISITS_QUERY = `query searchPatientEncountersQuery($patientId: String){
  searchPatientEncounters(patientId: $patientId)
  {
    id
    encounterType
    encounterLocation
    startDate
    endDate
    medicalServiceReceived
  }
}`;

@Component({
  selector: 'app-patient-facilities-visit',
  templateUrl: './patient-facilities-visit.component.html',
  styleUrls: ['./patient-facilities-visit.component.scss']
})
export class PatientFacilitiesVisitComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  isLoading = false;
  patientId: string;
  rowCount = 0;
  facilitiesVisitQuery: GraphQLRequest;
  dataSource: MatTableDataSource<PatientFacilitiesVisit>;
  displayedColumns = ["facilityName", "encounterType", "registrationDate", "dischargeDate"];

  constructor(
    private store: Store<IAppState>,
    private ciamAuth: CiamAuth,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UiService
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.patientId = params['id'];
    });

    this.getFacilitiesVisit();
  }

  async getFacilitiesVisit() {
    this.isLoading = true;

    this.facilitiesVisitQuery = {
      query: GET_FACILITY_VISITS_QUERY,
      variables: {
        'patientId': this.patientId
      }
    };

    const result = await this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.facilitiesVisitQuery);
    if (result && result.errors && result.errors.length) {
      this.isLoading = false;
      this.uiService.showErrors(result.errors);
      return;
    }

    const facilitiesVisit = result.data && result.data.searchPatientEncounters;
    if (facilitiesVisit) {
      this.dataSource = new MatTableDataSource(facilitiesVisit);
      this.rowCount = this.dataSource.data.length;
    }

    this.isLoading = false;
  }

  backToUsers() {
    this.router.navigate(['/users']);
  }
}
