import { OidcConfigService, LogLevel } from 'angular-auth-oidc-client'
import { environment } from '../../environments/environment';

export function configureAuth(oidcConfigService: OidcConfigService) {
    return () =>
        oidcConfigService.withConfig({
            stsServer: `${environment.ciamOktaUrl}/oauth2/${environment.idpId}`,
            redirectUrl: `${window.location.origin}`,
            clientId: environment.ciamOktaClientId,
            scope: 'openid profile email groups',
            responseType: 'code',
            triggerAuthorizationResultEvent: true,
            postLogoutRedirectUri: `${window.location.origin}`,
            renewTimeBeforeTokenExpiresInSeconds: environment.renewTimeBeforeTokenExpiresInSeconds,
            startCheckSession: false,
            silentRenew: true,
            silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
            postLoginRoute: '/home',
            forbiddenRoute: '/forbidden',
            unauthorizedRoute: '/unauthorized',
            logLevel: LogLevel.Debug,
            historyCleanupOff: true,
            useRefreshToken: false
        });
}