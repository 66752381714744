import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/types';
import * as _ from 'lodash';

import { EmpiHubCreateUpdateComponent } from 'app/components/empi/empi-hub-create-update/empi-hub-create-update.component';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { CiamUser, CiamAuth } from '@dignity-health/ciam-auth';
import { UpdateEmpiHubRequest } from '@dignity-health/ciam-auth/src/types/interfaces';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { UiService } from 'app/services/ui/ui.service';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-empi-hub',
  templateUrl: './empi-hub.component.html',
  styleUrls: ['./empi-hub.component.scss']
})

export class EmpiHubComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  showNoRowMsg: boolean;
  isLoading: boolean = false;
  canCreateEmpiHubs: boolean = false;

  dataSource: MatTableDataSource<UpdateEmpiHubRequest>
  @ViewChild(MatTable) table: MatTable<UpdateEmpiHubRequest>;

  displayedColumns = ["empiHubId", "empiHubName", "actions"];

  constructor(
    private ciamAuth: CiamAuth,
    private store: Store<IAppState>,
    private uiService: UiService,
    private oktaGroupService: OktaSupportGroupsService,
    private router: Router,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private barGroupService: BarGroupService
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
    this.canCreateEmpiHubs = this.oktaGroupService.isSuperAdmin;
  }

  ngOnInit() {
    this.getEMPIHubs();
  }

  getEMPIHubs(): void {
    this.barGroupService.getEMPIHubs().subscribe((value) => {
      this.showNoRowMsg = !value.length;
      this.dataSource = new MatTableDataSource(value);
    });

    this.barGroupService.isLoading.subscribe((value) => {
      this.isLoading = value;
    });
  }

  createEMPIHub() {
    this.dialog.open(EmpiHubCreateUpdateComponent, {
      width: '400px',
      data: null
    });
  }

  editEMPIHub(empiHub: UpdateEmpiHubRequest) {
    this.dialog.open(EmpiHubCreateUpdateComponent, {
      width: '400px',
      data: empiHub
    });
  }

  actionConfirmationData: IActionConfirmation = {
    matIcon: 'delete',
    modalTitle: 'Delete EMPI Hub Confirmation',
    modalInstruction: 'Are you sure you want to delete the selected EMPI Hub?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes',
    isAdvanceConfirmation: false
  }

  deleteEMPIHub(confirmationValue: boolean, empiHub: UpdateEmpiHubRequest) {
    if (confirmationValue) {
      this.isLoading = true;

      this.ciamAuth.httpApiFacilities.apiAdminBargroupRemoveEmpihubByIdDelete(empiHub.empiHubId)
        .then(response => {
          this.isLoading = false;

          if (response.isValid) {
            this.uiService.openSnackbar('Selected EMPI Hub is successfully deleted', 'Success');
            this.getEMPIHubs();
          }
          else if (response.errors.length > 0) {
            this.uiService.showErrors(response.errors)
          }
        });
    }
  }
}
