import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table'
import {MatPaginator, PageEvent} from '@angular/material/paginator'
import { Store } from '@ngrx/store';
import { IAppState } from 'app/types';
import { CiamUser, CiamAuth, UpdateBarGroupRequest } from '@dignity-health/ciam-auth';
import { BarGroupsCreateUpdateComponent } from '../bar-groups-create-update/bar-groups-create-update.component';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import { UiService } from 'app/services/ui/ui.service';
import { PagedResult } from '../../../types/graphql';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'bar-groups',
  templateUrl: './bar-groups.component.html',
  styleUrls: ['./bar-groups.component.scss']
})

export class BarGroupsComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  isSuperAdmin: boolean = false;
  isLoading: boolean = false;

  dataSource: MatTableDataSource<UpdateBarGroupRequest>
  @ViewChild(MatTable) table: MatTable<UpdateBarGroupRequest>;

  displayedColumns = ["groupId", "regionName", "empiHubName", "actions"];

  @ViewChild('paginator') paginator: MatPaginator;
  currentPage: number = 1;
  pageSize: number = 10;
  rowCount: number = 0;
  pageSizeOptions: number[] = [5, 10, 20];
  pagedResult: PagedResult<UpdateBarGroupRequest[]>;
  createUpdateBarGroupDialogRef: MatDialogRef<BarGroupsCreateUpdateComponent>;

  constructor(
    private store: Store<IAppState>,
    private oktaGroupService: OktaSupportGroupsService,
    private barGroupService: BarGroupService,
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private dialog: MatDialog
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
    this.isSuperAdmin = this.oktaGroupService.isSuperAdmin;
  }

  ngOnInit() {
    this.getBarGroups();
  }

  canCreateBarGroup(): boolean {
    return this.isSuperAdmin;
  }

  canUpdateAndDeleteBarGroup(): boolean {
    return this.isSuperAdmin;
  }

  getBarGroups(): void {
    this.isLoading = true;

    this.barGroupService.getPagedBarGroups(this.currentPage, this.pageSize).then(value => {
      this.isLoading = false;
      this.pagedResult = value as PagedResult<UpdateBarGroupRequest[]>;
      this.dataSource = new MatTableDataSource(this.pagedResult.results as UpdateBarGroupRequest[]);
      this.rowCount = this.pagedResult.rowCount;
    });
  }

  onPaginationChange(pageEvent: PageEvent) {
    this.pageSize = pageEvent == null ? this.pageSize : pageEvent.pageSize;
    this.currentPage = pageEvent == null ? this.currentPage : pageEvent.pageIndex + 1;

    this.getBarGroups();
  }

  createBarGroup() {
    this.openCreateEditBarGroupPopup(null);
  }

  editBarGroup(barGroup: UpdateBarGroupRequest) {
    this.openCreateEditBarGroupPopup(barGroup);
  }

  openCreateEditBarGroupPopup(barGroup: UpdateBarGroupRequest) {
    this.createUpdateBarGroupDialogRef = this.dialog.open(BarGroupsCreateUpdateComponent, {
      width: '400px',
      data: barGroup
    });

    this.createUpdateBarGroupDialogRef.afterClosed().subscribe(value => {
      if (value.status) {
        this.getBarGroups();
      }
    });
  }

  actionConfirmationData: IActionConfirmation = {
    matIcon: 'delete',
    modalTitle: 'Delete Bar Group Confirmation',
    modalInstruction: 'Are you sure you want to delete the selected Bar Group?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes', 
    isAdvanceConfirmation: false
  }

  deleteBarGroup(confirmationValue: boolean, barGroup: UpdateBarGroupRequest) {
    if (!confirmationValue) {
      return;
    }
    else {
      this.isLoading = true;

      this.ciamAuth.httpApiFacilities.apiAdminBargroupByIdDelete(barGroup.barGroupId)
        .then(response => {
          this.isLoading = false;

          if (response.errors.length > 0) {
            this.uiService.openSnackbar(response.errors[0].message, 'Error');
            return;
          }
          else if (response.isValid) {
            this.uiService.openSnackbar('Selected Bar Group is successfully deleted', 'Success');
            this.getBarGroups();
          }
        });
    }
  }
}
