<section class="page-content" *ngIf="user">
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <button mat-raised-button color="primary" [hidden]="!canCreateEmpiHubs" (click)="createEMPIHub()" class="empihub-button-margin">
    <mat-icon>add</mat-icon>Create EMPI Hub
  </button>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="empiHubId">
      <mat-header-cell *matHeaderCellDef fxFlex="20%">EMPI Hub Id</mat-header-cell>
      <mat-cell *matCellDef="let empiHubs" fxFlex="20%">{{empiHubs.empiHubId}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="empiHubName">
      <mat-header-cell *matHeaderCellDef fxFlex="60%">EMPI Hub Name</mat-header-cell>
      <mat-cell *matCellDef="let empiHubs" fxFlex="60%">{{empiHubs.empiHubName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef [hidden]="!canCreateEmpiHubs">Actions</mat-header-cell>
      <mat-cell *matCellDef="let empiHub" [hidden]="!canCreateEmpiHubs">
        <mat-icon (click)="editEMPIHub(empiHub)" mattooltip="Edit" fxFlex="30%">border_color</mat-icon>
        <action-confirmation [actionConfirmation]="actionConfirmationData" (userResponse)="deleteEMPIHub($event,empiHub)">
        </action-confirmation>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let empiHub; columns:displayedColumns"></mat-row>
  </mat-table>
  <div *ngIf="showNoRowMsg" class="text-center">
    No records to show!
  </div>
</section>
