<div>
    <section [ngSwitch]="resolvingUser">
        <section *ngSwitchCase="true">...finding user</section>

        <section *ngSwitchCase="false" [ngSwitch]="user != null">
            <section *ngSwitchCase="true">
                <span>{{user.username}}</span>
                <button mat-button (click)="signOut()"><mat-icon>lock</mat-icon>Sign Out</button>
            </section>
        </section>
    </section>
</div>