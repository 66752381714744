import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";
import { CiamAuth, InstructionRequest, GraphQLRequest } from "@dignity-health/ciam-auth";
import { UiService } from '../../services/ui/ui.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

const GET_INSTRUCTION_QUERY = `query {
      myHelpInstructions{
        instructionId
        title,
        description
        isActive
      }}`;

@Injectable()
export class InstructionService {
  public isLoading = new BehaviorSubject<boolean>(true);
  instructionQuery: GraphQLRequest;

  constructor(private ciamAuth: CiamAuth, private uiService: UiService) { }

  async getMyHelpInstructions() : Promise<InstructionRequest> {
    this.instructionQuery = {
      query: GET_INSTRUCTION_QUERY,
      variables: {
      }
    };

    const result = await this.ciamAuth.httpApi.apiInfoQueryPost(this.instructionQuery, false);
    
    if (result && result.errors && result.errors.length) {
      this.showErrorMessage(result);
      return;
    }

    const instruction = result.data && result.data.myHelpInstructions;

    return instruction as InstructionRequest;
  }

  setIsLoading(val: boolean) {
    this.isLoading.next(val);
  }

  private showErrorMessage(result: any) {
    this.setIsLoading(false);
    this.uiService.showErrors(result.errors);
    return;
  }
} 
