<progress-bar [isLoading]="isLoading"></progress-bar>
<mat-tab-group class="tab-root">
  <mat-tab label="Basic Info">
    <div class="basic-info-form">
      <mat-form-field>
        <input matInput placeholder="Name" name="friendlyFacilityName" [(ngModel)]="request.friendlyFacilityName" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Scheduling Location Id" name="schedulingLocationId" [(ngModel)]="request.schedulingLocationId" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="EDW Facility Name" name="edwFacilityName" [(ngModel)]="request.edwFacilityName" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Registration System" name="registrationSystem" [(ngModel)]="request.registrationSystem" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Web Address" name="webAddress" [(ngModel)]="request.webAddress" />
      </mat-form-field>

      <mat-form-field>
        <mat-chip-list #alternateNames>
          <mat-chip *ngFor="let altName of request.alternateFacilityNames" [removable]="true" (remove)="onRemoveAlternateFacilityName(altName)">
            {{altName}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <input class="alternate-names-input" #alternateNamesInput [matChipInputFor]="alternateNames" [matChipInputSeparatorKeyCodes]="chipSeparatorKeyCodes"
          (matChipInputTokenEnd)="onAddAlternateFacilityName(alternateNamesInput, $event.value)" placeholder="Alternate Names"
          name="alternateNames" />
      </mat-form-field>

      <mat-slide-toggle class="is-active-control" [(ngModel)]="request.isActive">Active?</mat-slide-toggle>
    </div>
  </mat-tab>

  <mat-tab label="Address Info">
    <div class="address-info-form">
      <mat-form-field>
        <input matInput placeholder="Latitude" name="latitude" [(ngModel)]="request.latitude">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Longitude" name="longitude" [(ngModel)]="request.longitude">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Line 1" name="addressLine1" [(ngModel)]="request.address.line1">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Line 2" name="addressLine2" [(ngModel)]="request.address.line2">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="City" name="city" [(ngModel)]="request.address.city">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="State Abbreviation" name="stateOrProvince" [(ngModel)]="request.address.stateOrProvince">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Zip Code" name="zipCode" [(ngModel)]="request.address.zipCode">
      </mat-form-field>
    </div>
  </mat-tab>

  <mat-tab label="Domain Associations">
    <div class="domain-associations-form">
      <div *ngFor="let association of request.facilityDomains | active">
        <facility-domain-association [domains]="domains" [domainAssociation]="association" (remove)="onRemoveDomainAssociation($event)"></facility-domain-association>
      </div>

      <facility-domain-association [domains]="domains" [pending]="true" (add)="onAddDomainAssociation($event)" #facilityDomainAssociationComponent></facility-domain-association>
    </div>
  </mat-tab>

  <mat-tab label="Bar Group &amp; EMPI Hub Info">
    <div class="bar-group-and-empi-form">
      <mat-form-field>
        <mat-select placeholder="Bar Group (Group : Region : EMPI Hub)" [(value)]="request.barGroupId">
          <mat-option *ngFor="let barGroup of barGroups" [value]="barGroup.barGroupId">
            {{barGroup.groupId}} : {{barGroup.region}} : {{barGroup.empiId}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-content></ng-content>
