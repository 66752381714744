import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE } from 'app/types/constants';
import { PatientEnrollmentSearchRequest } from '../../../types/patient-enrollment-search-request';
import { UiService } from 'app/services/ui/ui.service';
import { max } from "rxjs/operators";
import { environment } from "environments/environment";

@Component({
    selector: 'app-patient-enrollments-search',
    templateUrl: './patient-enrollments-search.component.html',
    styleUrls: ['./patient-enrollments-search.component.scss']
})

export class PatientEnrollmentsSearchComponent implements OnInit {
    @Input() searchType: string;
    patientEnrollmentsSearchForm: FormGroup;
    // Allowing any date but the year should be 4 digit
    
    maxDate = new Date();
    fromDate = new Date();
    maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;

    @Input() hasResults = false;
    @Input() isLoading = false;
    @Output() patientEnrollmentsSearchParams = new EventEmitter<PatientEnrollmentSearchRequest>();

    constructor(private fb: FormBuilder, private uiService: UiService) { }

    ngOnInit() {
        this.resetPatientEnrollmentSearchParams();        
    }

    modifySearch() {
        if (!this.patientEnrollmentsSearchForm.valid) {
            return;
        }
        const controls = this.patientEnrollmentsSearchForm.controls;

        const patientEnrollmentSearchObject: PatientEnrollmentSearchRequest = <PatientEnrollmentSearchRequest>{
            portalName: controls['portalName'].value.trim(),
            userName: controls['userName'].value.trim(),
            status: controls['states'].value,
            patientEnrollmentType: controls['type'].value,
            startDateTime: controls['fromDate'].value,
            endDateTime: controls['toDate'].value
        };

        this.patientEnrollmentsSearchParams.emit(patientEnrollmentSearchObject);
        this.hasResults = true;
    }

    resetPatientEnrollmentSearchParams() {
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - environment.enrollmentsErrorNumberOfDays);
        this.patientEnrollmentsSearchForm = this.fb.group({
            'portalName': [''],
            'userName': [''],
            'states': null,
            'type': null,
            'fromDate': fromDate,
            'toDate': new Date()
        });
    }

    patientEnrollmentStatuses = [
        { value: 'InProgress', name: 'In Progress' },
        { value: 'Completed', name: 'Completed' },
        { value: 'Error', name: 'Error' }
    ];

    patientEnrollmentTypes = [
        { value: 'Migration', name: 'Migration' },
        { value: 'SelfEnrollment', name: 'Self Enrollment' },
        { value: 'Invitation', name: 'Invitation' }
    ];
}
