import { Component, OnInit } from '@angular/core';
import { SEARCH_TYPE } from 'app/shared/enums/enums';

@Component({
  selector: 'users-and-patients',
  templateUrl: './users-and-patients.component.html',
  styleUrls: ['./users-and-patients.component.scss']
})
export class UsersAndPatientsComponent implements OnInit {

  searchType: string = SEARCH_TYPE.MY_HOME;
  constructor() { }

  ngOnInit() {
    if (localStorage.getItem("searchType") != null) {
      this.searchType = JSON.parse(localStorage.getItem('searchType'));
    }
  }

  searchTypeChanged(searchType: string) {
    this.searchType = searchType;
  }

  checkIfUsersSearch() {
    return this.searchType == SEARCH_TYPE.MY_HOME;
  }

  checkIfPatientsSearch() {
    return this.searchType == SEARCH_TYPE.MY_CARE || this.searchType == SEARCH_TYPE.CERNER;
  }
}
