import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

import { Router } from '@angular/router';
import { CiamUser } from '@dignity-health/ciam-auth';
import { IAppState } from 'app/types';
import { UpdateUserSearch } from 'app/store/actions';
import { UsersDataSource } from 'app/types/users-datasource';
import { PageInfo, SortInfo } from '../../../types/models';
import { UiService } from '../../../services/ui/ui.service';
import { GraphQLQuery, PagedResult } from '../../../types/graphql';
import { UserSearchRequest } from '../../../types/userSearchRequest';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators'

const GET_USERS_QUERY = `
query getUsers( $basicUserSearchText: String, 
                $isAdvancedUserSearch: Boolean, 
                $username: String,
                $firstName: String,
                $lastName: String,                 
                $dateOfBirth: String, 
                $gender: String,
                $idLevel: String,
                $createdDate: String,
                $inQuickerConfirmationNumber: String,  
                $sortColumn: String,
                $sortOrder: String,                  
                $pageSize: Int,                
                $currentPage: Int) {
                searchUsers(
                    searchParameters: { 
                      basicUserSearchText: $basicUserSearchText, 
                      isAdvancedUserSearch: $isAdvancedUserSearch,
                      username: $username,
                      firstName: $firstName,
                      lastName: $lastName,
                      gender: $gender
                      idLevel: $idLevel
                      dateOfBirth: $dateOfBirth,
                      createdDate: $createdDate,
                      inQuickerConfirmationNumber: $inQuickerConfirmationNumber
                    }, 
                    pageInfo: { 
                      pageSize: $pageSize,
                      currentPage: $currentPage 
                    },
                   sortInfo: {     
                    sortColumn: $sortColumn,
                    sortOrder: $sortOrder
                     }) 
    {
      pageSize
      currentPage
      firstRowOnPage
      lastRowOnPage
      pageCount
      rowCount
      results {
        username,
        firstName,
        lastName,
        dateOfBirth,
        gender,
        idLevel,
        createdDate
      }
    }
}
`;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  user: CiamUser;
  user$: Observable<CiamUser>;
  @ViewChild(MatTable) table: MatTable<CiamUser>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  rowCount = 0;
  hasResults = false;
  noResultsMessage: string;
  loadingIndicator: boolean;
  searchUsersQuery: GraphQLQuery;
  userSearchRequest: UserSearchRequest;
  dataSource: MatTableDataSource<CiamUser>;
  advancedSearch$ = new BehaviorSubject<UserSearchRequest>({
    'basicUserSearchText': '',
    'isAdvancedUserSearch': false,
    'username': '',
    'firstName': '',
    'lastName': '',
    'dob': null,
    'gender': null,
    'userLevel': null,
    'createdDate': null,
    'inQuickerConfirmationNumber': ''
  });

  pageInfo$ = new BehaviorSubject<PageInfo>({
    'pageSize': 10,
    'currentPage': 1
  });

  sortInfo: SortInfo = { sortColumn: "", sortOrder: "" };
  displayedColumns = ['userName', 'firstName', 'lastName', 'dateOfBirth', 'gender', 'idLevel', 'createdDate'];

  constructor(
    private router: Router,
    private uiService: UiService,
    private cd: ChangeDetectorRef,
    private store: Store<IAppState>,
    public usersDataSource: UsersDataSource
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => { this.user = user; });

    this.store.select(m => m.userSearch).subscribe(s => {
      if (s != undefined && (s.myHomeSearch != null)) {
        this.userSearchRequest = s.myHomeSearch;
      }
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.getUsers();
  }

  onPaginateChange(pageEvent: PageEvent) {
    this.pageInfo$.next({
      'pageSize': pageEvent.pageSize,
      'currentPage': pageEvent.pageIndex + 1
    });
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.sortInfo = { sortColumn: sort.active, sortOrder: sort.direction };
    this.getUsers();
  }

  getUsers(): void {
    const combined = combineLatest(this.advancedSearch$, this.pageInfo$);
    combined.pipe(distinctUntilChanged())
      .subscribe((searchRequest: [UserSearchRequest, PageInfo]) => {
        this.noResultsMessage = '';
        this.loadingIndicator = true;
        const searchText = searchRequest[0].basicUserSearchText;

        if ((searchText === '' || searchText === undefined) && !searchRequest[0].isAdvancedUserSearch) {
          this.hasResults = false;
          this.loadingIndicator = false;
          return;
        }

        this.store.dispatch(new UpdateUserSearch(searchRequest[0]));

        // Rebuild Query, so that there is no stale data
        this.searchUsersQuery = {
          operationName: null,
          query: GET_USERS_QUERY,
          variables: {
            'basicUserSearchText': searchRequest[0].basicUserSearchText,
            'isAdvancedUserSearch': searchRequest[0].isAdvancedUserSearch,
            'username': searchRequest[0].username,
            'firstName': searchRequest[0].firstName,
            'lastName': searchRequest[0].lastName,
            'dateOfBirth': this.uiService.formatDate(searchRequest[0].dob),
            'gender': searchRequest[0].gender,
            'idLevel': searchRequest[0].userLevel,
            'createdDate': this.uiService.formatDate(searchRequest[0].createdDate),
            'inQuickerConfirmationNumber': searchRequest[0].inQuickerConfirmationNumber,
            'sortColumn': this.sortInfo.sortColumn,
            'sortOrder': this.sortInfo.sortOrder,
            'pageSize': searchRequest[1].pageSize,
            'currentPage': searchRequest[1].currentPage
          }
        };

        this.usersDataSource.searchUsers(this.searchUsersQuery)
          .then(response => {
            // Handle the errors elegantly.
            if (response && response.errors && response.errors.length) {
              this.hasResults = false;
              this.loadingIndicator = false;
              return this.uiService.showErrors(response.errors);
            }

            if (response && response.data !== null && response.data !== undefined) {
              const pagedResult = <PagedResult<CiamUser>>(response.data.searchUsers);
              if (pagedResult && pagedResult.rowCount > 0) {
                this.dataSource = new MatTableDataSource(<CiamUser[]>(pagedResult.results));
                this.rowCount = pagedResult.rowCount;
                this.hasResults = true;

                // If no record is available on the page searched, take the user back to page 1.
                if ((pagedResult.currentPage !== 1)
                  && (pagedResult.rowCount < ((pagedResult.currentPage - 1) * pagedResult.pageSize))) {
                  this.paginator.pageIndex = 0;
                  this.pageInfo$.next({
                    currentPage: 1,
                    pageSize: 10
                  });
                }
              }
              else {
                this.hasResults = false;
                this.noResultsMessage = `No results found.`;
              }
              this.loadingIndicator = false;
            }
          })
          .catch(errors => {
            this.loadingIndicator = false;
            this.hasResults = false;
            return this.uiService.showErrors(errors);
          });
      });
  }

  goToViewUserProfile(username: string) {
    this.router.navigate(['/users/view', { user: username }]);
  }

  advancedSearch(advanceSearchParms: UserSearchRequest) {
    this.advancedSearch$.next(advanceSearchParms);
  }

  showNoResults() {
    this.hasResults = false;
    this.loadingIndicator = false;
    this.noResultsMessage = `No results found.`;
  }
}
