<h1 mat-dialog-title>{{data.action.modalTitle}}</h1>
<mat-dialog-content>
  <div mat-dialog-content>
    <span>{{data.action.modalInstruction}}</span>
  </div>
</mat-dialog-content>

<mat-dialog-content class="popup-content padding-bottom" *ngIf="data.action.isAdvanceConfirmation">
  <div mat-dialog-content>
    {{data.action.advanceConfirmationInstruction}}
  </div>
  <div mat-dialog-content>
    <input type="text" (keyup)="validate($event)" />
  </div>
</mat-dialog-content>

<mat-dialog-actions class="popup-content">
  <button mat-button [mat-dialog-close]="false">{{data.action.cancelButtonText}}</button>
  <button [disabled]="disableConfirmButton" mat-button [mat-dialog-close]="true">{{data.action.okButtonText}}</button>
</mat-dialog-actions>
