import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IResetInformation } from 'app/types/reset-information';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { PatientInformation } from 'app/types/patient-information';
import { GraphQLQuery } from 'app/types/graphql';
import { IResetUserRequest } from 'app/types/resetUserRequest';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import * as constant from '../../types/constants';
import { EnrollmentTypeInfo } from 'app/types/enrollmentTypeInfo';

const GET_PATIENTDETAILS_FOR_COMPLETED_ENROLLMENT_QUERY = `
  query getRelatedPatients($userId: Int, $getCompletedEnrollments: Boolean) {
    getRelatedPatients(userId: $userId, getCompletedEnrollments: $getCompletedEnrollments) {
      portalName,
      firstName,
      lastName,
      sourceId,
      patientDateOfBirth,
      enrollmentStatus
    }
  }`;

@Component({
    selector: 'app-reset-options-dialog',
    templateUrl: './reset-options-dialog.component.html',
    styleUrls: ['./reset-options-dialog.component.scss']
})

export class ResetOptionsDialogComponent {
    resetInformation: IResetInformation;
    hasInvitationPatients = true;
    isMigrationSelected = false;
    disableResetToLevel2 = true;
    displayedColumns =  ['sourceId', 'firstName', 'lastName', 'dateOfBirth', 'enrollmentStatus'];
    availableEnrollementTypes: string [];
    dataSource: PatientInformation[];
    patientEnrollmentQuery: GraphQLQuery;
    showDataLoading = false;
    displayError = true;
    confirmationText = '';
    confirmationTextMatched = false;
    instruction: string;
    errorObtainingData = false;
    enrollmentTypes: EnrollmentTypeInfo[] = [];

    resetUserRequest: IResetUserRequest = {
      sourceId: '',
      deleteUser: false,
      resetToLevel2: true,
      resetIndividualUser: false
    }

    constructor(private ciamAuth: CiamAuth,
      private oktaGroupService: OktaSupportGroupsService,
      private dialogRef: MatDialogRef<ResetOptionsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {

      this.resetInformation = this.data.resetInfo;
      this.resetUserRequest.portalName = this.resetInformation.portalName;

      if (this.resetInformation.enrollmentDetails != null) {
          this.getEnrollmentTypesForPortal();

          this.setHeaderData();

          if (this.hasInvitationPatients) {
            this.getInvitationUserData();
          }
      }
    }

    setHeaderData() {
      this.instruction =  this.resetInformation.instruction.replace('{0}', this.resetInformation.userData.firstName).
                                            replace('{1}', this.resetInformation.userData.lastName).
                                            replace('{2}', this.resetInformation.portalName);
    }

    getDistinctPortals() {
      return this.resetInformation.enrollmentDetails.filter(
          (enrollment, i , arr)  => arr.findIndex(x => x.portalName  === enrollment.portalName) === i).map(x => x.portalName);
    }

    getEnrollmentTypesForPortal() {
      this.getDistinctEnrollments();
      this.resetUserRequest.enrollmentType = this.enrollmentTypes[0].enrollmentType;
      this.setMigrationSelected();
      this.determineEnrollmentType(this.resetUserRequest.enrollmentType);
    }

    getDistinctEnrollments() {
      const distinctEnrollments = this.resetInformation.enrollmentDetails.filter((enrollment, i , arr) =>
          arr.findIndex(x =>
              x.patientEnrollmentType  === enrollment.patientEnrollmentType &&
              x.portalName === this.resetUserRequest.portalName) === i)
              .map(x =>
                  (x.patientEnrollmentType[0].toUpperCase() + x.patientEnrollmentType.substr(1).toLowerCase()));

      distinctEnrollments.sort();
      distinctEnrollments.forEach((data) => {
        if (data != null) {
          const hasError = this.resetInformation.enrollmentDetails.findIndex(x =>
             x.portalName === this.resetUserRequest.portalName &&
             x.patientEnrollmentType === data.toLocaleLowerCase() &&
             x.status !== constant.COMPLETED) > -1;

          const displayName = hasError ? (data + ' - Error') : data;

          this.enrollmentTypes.push(new EnrollmentTypeInfo(data, hasError, displayName));
        }
      });
    }

    determineEnrollmentType(distinctEnrollments: any) {
      if (distinctEnrollments.length === 1) {
          if (distinctEnrollments[0].toLowerCase() === constant.MIGRATION) {
              this.hasInvitationPatients = false;
          }
      }
    }

    getInvitationUserData() {
      this.showDataLoading = true;
      this.patientEnrollmentQuery = {
        query: GET_PATIENTDETAILS_FOR_COMPLETED_ENROLLMENT_QUERY,
        variables: {
          'userId': this.resetInformation.userData.userId,
          'getCompletedEnrollments': false
        }
      };

      this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.patientEnrollmentQuery)
        .then(patientEnrollments => {
            const patientData = patientEnrollments && patientEnrollments.data && patientEnrollments.data.getRelatedPatients;

            this.showDataLoading = false;
            if (patientData) {
              this.resetInformation.patientDetails = patientData;
              this.assignTableData();
            } else {
              this.errorObtainingData = true;
            }
        }).catch(() => {
          this.showDataLoading = false;
          this.errorObtainingData = true;
        });

    }

    assignTableData() {
      this.dataSource = this.resetInformation.patientDetails.filter(x => x.portalName === this.resetUserRequest.portalName);

      this.dataSource.forEach((data) => {
        data.enrollmentStatus = data.enrollmentStatus[0].toUpperCase() + data.enrollmentStatus.substr(1).toLowerCase();
      });
    }

    onEnrollmentTabChanged(tabEvent: any) {
      this.resetUserRequest.enrollmentType = tabEvent.tab.textLabel;
      this.resetUserRequest.resetIndividualUser = false;
      this.confirmationText = ''
      this.confirmationTextMatched = false;
      this.setMigrationSelected();
    }

    onResetIndUserChanged(checkBox: any) {
      this.resetUserRequest.deleteUser = false;
      this.resetUserRequest.resetToLevel2 = !checkBox.checked;
      this.resetUserRequest.sourceId = '';
      this.displayError = true;
    }

    onIndividualUserSelection(radioElement: any) {
      if (radioElement.source.checked) {
          this.resetUserRequest.sourceId = radioElement.value.sourceId;
          this.displayError = false;
          const selectedPatient = this.resetInformation.patientDetails.find(x => x.sourceId === this.resetUserRequest.sourceId);
          this.disableResetToLevel2 =
              !(selectedPatient.firstName.trim().toLowerCase() === this.resetInformation.userData.firstName.trim().toLowerCase()
              && selectedPatient.lastName.trim().toLowerCase() === this.resetInformation.userData.lastName.trim().toLowerCase() &&
              selectedPatient.patientDateOfBirth === this.resetInformation.userData.dateOfBirth.toString());
          this.resetUserRequest.resetToLevel2 = !this.disableResetToLevel2;
      }
    }

    setMigrationSelected() {
      this.isMigrationSelected = (this.resetUserRequest.enrollmentType.toLowerCase() === constant.MIGRATION);
    }

    validate(event: any) {
      this.confirmationTextMatched = false;
      this.confirmationText = this.confirmationText.trim();

      if (this.confirmationText === this.resetInformation.advanceConfirmationValidationText) {
        this.confirmationTextMatched = true;
      }
    }

    resetRequestDetails() {
      this.dialogRef.close(this.resetUserRequest);
    }

    cancelReset() {
      this.dialogRef.close(false);
    }
}
