import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { MatTableDataSource,MatTable } from '@angular/material/table';
import { IAppState } from '../../../types/state';
import { CiamUser } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { AlertSettingRequest, AlertSettingResponse } from '@dignity-health/ciam-auth/src/types/interfaces';
import { SiteSettingService } from 'app/services/site-settings/site-settings-service';
import { AlertSettingUpdateComponent } from '../alert-setting-update/alert-setting-update.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alert-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss']
})

export class AlertSettingsComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  isLoading: boolean = false;

  dataSource: MatTableDataSource<AlertSettingResponse>;
  @ViewChild(MatTable)
  table: MatTable<AlertSettingResponse>;

  displayedColumns = ['alertName', 'threshold', 'ryverAlert', 'emailAlert', 'reoccurringThreshold', 'reoccurringryverAlert', 'reoccurringemailAlert', 'emailTo', 'actions'];
  constructor(
    private siteSettingService: SiteSettingService,
    private store: Store<IAppState>,
    private dialog: MatDialog) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.getAlertSettingsList();
  }

  getAlertSettingsList() {
    this.siteSettingService.getAlertSetting().subscribe((value) => {
      this.dataSource = new MatTableDataSource(value);
    });

    this.siteSettingService.isLoading.subscribe((value) => {
      this.isLoading = value;
    });
  }

  editAlertSetting(alertSetting: AlertSettingRequest) {
    this.dialog.open(AlertSettingUpdateComponent, {
      width: '600px',
      data: alertSetting
    });
  }
}
