import { CiamAuth, CiamUser, ServiceResponse, TokenType } from '@dignity-health/ciam-auth';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, NavigationEnd, NavigationStart, RouterLinkActive } from '@angular/router';
import { GutterSize, ContentWidth, NavWidth, GutterDefaultSize } from './types/constants';
import { environment } from 'environments/environment';
import { UiService } from './services/ui/ui.service';
import { OktaSupportGroupsService } from './services/okta-group/okta-support-group.service';
import { OidcSecurityService, } from 'angular-auth-oidc-client';
import { ValidGroups } from '../app/types/constants';
import { Store, createAction, props } from '@ngrx/store';
import { IAppState } from 'app/types/state';
import { UpdateUser } from './store/actions/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showMenu$: Observable<boolean>;
  toggleMenu: boolean = false;
  noWidth: number = 0;
  gutterSize: number = 1;
  contentFullWidth: number = 100;
  navWidth: number = this.noWidth;
  contentWidth: number = this.contentFullWidth;
  showLoader: boolean = true;
  user: CiamUser;
  user$: Observable<CiamUser>;
  flags = environment.featureFlags;
  resolvingUser: boolean;
  showSpinner = false;

  constructor(
    private ciamAuth: CiamAuth,
    private router: Router,
    private oktaGroupService: OktaSupportGroupsService,
    public uiService: UiService,
    private oidcSecurityService: OidcSecurityService,
    private store: Store<IAppState>
  ) {
    this.user$ = this.store.select(m => m.user);
    this.user$.subscribe(user => { this.user = user; });
  }

  expandLeftMenu() {
    this.toggleMenu = !this.toggleMenu;

    if (this.toggleMenu) {
      this.contentWidth = ContentWidth;
      this.navWidth = NavWidth;
    }
    else {
      this.navWidth = this.noWidth;
      this.contentWidth = this.contentFullWidth;
    }
  }

  ngOnInit() {
    this.router.events.subscribe(
      async event => {
        if (event instanceof NavigationStart) {
          this.showLoader = true;
        }

        if (event instanceof NavigationEnd) {
          this.oidcSecurityService.isAuthenticated$.subscribe((isAuthenticated) => {
            if (isAuthenticated) {
              const sessionStorageKey = `${environment.ciamOktaClientId}_authnResult`
              const tokenDetails = sessionStorage.getItem(sessionStorageKey);

              if (tokenDetails && tokenDetails != "null") {
                const tokenObject = JSON.parse(tokenDetails);
                this.ciamAuth.session.setIdToken({
                  idToken: tokenObject.access_token,
                  expiresAt: tokenObject.expires_in,
                  accessToken: tokenObject.access_token,
                  scopes: tokenObject.scope
                });
              }


              const idToken = this.oidcSecurityService.getIdToken()
              if (!idToken) {
                this.showMenu$ = of(false);
                this.toggleMenu = false;
                this.contentWidth = this.contentFullWidth;
                this.navWidth = this.noWidth;
                this.gutterSize = GutterDefaultSize;
              } else {
                this.showMenu$ = of(true);
                this.toggleMenu = true;
                this.contentWidth = ContentWidth;
                this.navWidth = NavWidth;
                this.gutterSize = GutterSize;

              }

              this.showLoader = false;
              const tokenString = this.oidcSecurityService.getToken();

              if (!tokenString) {
                this.showLoader = false;
                return this.router.navigate(['login']);
              }


              const token: { payload: { groups: string[] } } = this.ciamAuth.authClient.token.decode(tokenString);
              const groups = token.payload.groups;
              this.oktaGroupService.checkOktaGroup(groups);
              var IsValidUser = groups && groups.some(ug => {
                return ValidGroups.some(vg => {
                  return vg == ug;
                });
              });
              if (!IsValidUser) {
                this.signOut();
                this.showSpinner = false;
                this.uiService.openSnackbar('Sorry, You are not authorized to access this application!');
              }
              else {
                if (!this.user) {
                  this.getLoggedInUser().then(user => {
                    this.updateUser(user);
                  })
                }
              }

              if (!this.oktaGroupService.isAuthorizedRoute(event.url.substring(1))) {
                return this.router.navigate(['unauthorized']);
              }
            }
          })
          this.showLoader = false;
        }

      });
  }

  showFacilitiesNavGroup(): boolean {
    const flags = environment.featureFlags.navigation.facilities;

    return flags.create || flags.list;
  }

  showBarGroupNavGroup(): boolean {
    const barGroupFlags = environment.featureFlags.navigation.barGroup;
    return barGroupFlags.region.list || barGroupFlags.empihub.list || barGroupFlags.list;
  }

  showSupportLog(): boolean {
    const supportlog = environment.featureFlags.navigation.logs;
    return supportlog.search || supportlog.enrollments.list;
  }

  showSiteSettings(): boolean {
    const siteSetting = environment.featureFlags.navigation.siteSettings;
    return siteSetting.view;
  }

  showMailSettings(): boolean {
    const mailSetting = environment.featureFlags.navigation.siteSettings.domainWhitelisting;
    return mailSetting.create;
  }

  showAzureSearch(): boolean {
    const azureSearch = environment.featureFlags.navigation.azureSearch;
    return azureSearch.view && this.oktaGroupService.isSuperAdmin;
  }

  showDevelopersOptions(): boolean {
    const developersOptions = environment.featureFlags.navigation.developersOptions;
    return developersOptions.view;
  }

  private updateUser(user: CiamUser) {
    this.user = user;
    this.store.dispatch(new UpdateUser(user));
  }

  async signOut(): Promise<void> {
    this.doUserResolvingAction(async () => {
      await this.ciamAuth.session.signOut();
      this.updateUser(null);
      this.router.navigate(['login']);
    });
  }

  private doUserResolvingAction<T>(action: () => Promise<T>): Promise<T> {
    this.resolvingUser = true;

    const promise = action();

    promise.catch(error => {
      this.uiService.openSnackbar(error.toString(), null, { duration: 2500 });

      this.resolvingUser = false;
    });

    promise.then(() => this.resolvingUser = false);

    return promise;
  }
  private async getLoggedInUser(): Promise<CiamUser> {
    return this.doUserResolvingAction(async () => {
      const getUserResponse = await this.ciamAuth.user.getProfile();
      return getUserResponse.result;
    });
  }

}