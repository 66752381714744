import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IActionConfirmation } from '../../types/action-confirmation-model/action-confirmation-model';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'action-confirmation',
    templateUrl: './action-confirmation.component.html',
    styleUrls: ['./action-confirmation.component.scss']
})
export class ActionConfirmationComponent {

    @Input() actionConfirmation: IActionConfirmation;
    @Output() userResponse: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    constructor(public dialog: MatDialog) { }

    openConfirmationDialog() {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
              action: this.actionConfirmation
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.userResponse.emit(result);
        });
    }
}
