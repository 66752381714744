import { Component, Input, ViewChild } from '@angular/core';
import { BarGroup, UpdateFacilityRequest } from '@dignity-health/ciam-auth/src/types/interfaces';
import { FacilitiesService } from '../../../services/facilities/facilities-service';
import { UiService } from '../../../services/ui/ui.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../types';
import { FacilityDomainInfo, FacilityDomain, CreateFacilityRequest } from '@dignity-health/ciam-auth';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FacilityDomainAssociationComponent } from 'app/components/facility/facility-domain-association/facility-domain-association.component';

@Component({
  selector: 'facilities-create-update',
  templateUrl: './facilities-create-update.component.html',
  styleUrls: ['./facilities-create-update.component.scss']
})
export class FacilitiesCreateUpdateComponent {
  domains: FacilityDomainInfo[];
  barGroups: BarGroup[];

  isLoading: boolean = false;
  chipSeparatorKeyCodes = [ENTER, COMMA];

  @Input()
  request: CreateFacilityRequest | UpdateFacilityRequest;

  @ViewChild("facilityDomainAssociationComponent") child: FacilityDomainAssociationComponent;

  constructor(
    private store: Store<IAppState>,
    private facilitiesService: FacilitiesService,
    private uiService: UiService) {

    // Kick off a related info refresh
    this.isLoading = true;

    // subscribe to newest general info and pick out domains, bar groups, etc.
    this.store.select(s => s.info)
      .subscribe(info => {
        if (!info) {
          return this.refreshFacilitiesRelatedInfo();
        }

        this.isLoading = false;

        this.domains = info.domains;
        this.barGroups = info.barGroups;
      });
  }

  checkDomainFields = function () {
    return this.child.checkFields();
  }

  onAddDomainAssociation(association: FacilityDomain) {
    this.request.facilityDomains = this.request.facilityDomains || [];

    this.request.facilityDomains.push(association);
  }

  onRemoveDomainAssociation(association: FacilityDomain) {
    const domains = this.request.facilityDomains;

    const indexOfAssociation = domains.indexOf(association);
    domains.splice(indexOfAssociation, 1);
  }

  onAddAlternateFacilityName(inputElement: HTMLInputElement, name: string) {
    this.request.alternateFacilityNames = this.request.alternateFacilityNames || [];
    this.request.alternateFacilityNames.push(name);

    inputElement.value = null;
  }

  onRemoveAlternateFacilityName(name: string) {
    const names = this.request.alternateFacilityNames;

    const indexOfName = names.indexOf(name);
    names.splice(indexOfName, 1);
  }

  async refreshFacilitiesRelatedInfo(): Promise<void> {
    // Fire off request to get all domain/bargroup/etc info for population later
    const response = await this.facilitiesService.getAllFacilitiesRelatedInfo(true);
    if (response.errors && response.errors.length) {
      return this.uiService.showErrors(response.errors);
    }
  }
}
