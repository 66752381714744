<div class="empihub-container">
  <h1 mat-dialog-title>EMPI Hub Details</h1>
  <mat-form-field>
    <input matInput placeholder="Enter EMPI Hub Name" [formControl]="empiHubName" cdkFocusInitial required />
    <mat-error *ngIf="this.empiHubName.hasError('required')">EMPI Hub is required</mat-error>
  </mat-form-field>
  <div mat-dialog-actions>
    <button mat-button matDialogClose color="accent">Cancel</button>
    <button mat-button (click)="!empiHubName.invalid && saveEMPIHub()" color="primary">Save</button>
    <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="empiHub-loader-margin"  [diameter]="30"></mat-spinner>
  </div>
</div>
