import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-status-dialog',
    templateUrl: 'reset-user-password-status.component.html',
})

export class ResetUserPasswordStatusComponent {
    constructor( @Inject(MAT_DIALOG_DATA) public data: ResetUserPasswordStatus) { }
}

export interface ResetUserPasswordStatus {
    message: string;
}
