import { Injectable } from "@angular/core";
import { CiamAuth, GraphQLRequest, PortalSupportEvent, ServiceResponse } from "@dignity-health/ciam-auth";
import { PagedResult } from '../../types/graphql';
import { UiService } from '../../services/ui/ui.service';

const GET_PORTAL_LOG_QUERY = `query getPatientLogs($currentPage: Int, $pageSize: Int, $username: String, $errorCode: String, $fromDate: Date, $toDate: Date)
{
  patientLogs
  (
    pageInfo: {currentPage: $currentPage, pageSize: $pageSize},
    portalLogsSearchParameters:
    {
      username: $username,
      errorCode: $errorCode,
      fromDate: $fromDate,
      toDate: $toDate,
      severityLevels: [1,2,3]
    }
  )
  {
    pageSize
    currentPage
    pageCount
    rowCount
    results
    {
      itemId
      timestamp
      errorCode
      message
      level 
      username
    }
  }
}`;

@Injectable()
export class PortalLogViewerService {
  portalLogQuery: GraphQLRequest;

  constructor(private ciamAuth: CiamAuth, private uiService: UiService) { }

  private showErrorMessage(result: any) {
    this.uiService.showErrors(result.errors);
    return;
  }

  async getPortalLogs(currentPage: number, pageSize: number, username: string, errorcode: string, fromDate: Date, toDate: Date)
    : Promise<PagedResult<PortalSupportEvent[]>> {
    this.portalLogQuery = {
      query: GET_PORTAL_LOG_QUERY,
      variables: {
        currentPage: currentPage,
        pageSize: pageSize,
        username: username,
        errorCode: errorcode,
        fromDate: fromDate ? new Date(fromDate).toISOString() : null,
        toDate: toDate ? new Date(toDate).toISOString() : null,
      }
    };

    const result = await this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.portalLogQuery);
    
    if (result && result.errors && result.errors.length) {
      this.showErrorMessage(result);
      return;
    }

    const portalLogs = result.data && result.data.patientLogs;
    
    return portalLogs as PagedResult<PortalSupportEvent[]>;
  }
} 
