import { Component, OnInit, Input } from '@angular/core';
import { User, CiamAuth, Appointment } from '@dignity-health/ciam-auth';
import { MatTableDataSource } from '@angular/material/table';
import { GraphQLQuery } from 'app/types/graphql';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'app/services/ui/ui.service';
import { MIN_DATE } from 'app/types/constants';

const GET_APPOINTMENTS_BY_USERNAME_QUERY = `
query getAppointmentsByUserName($username: String, $includeInactive: Boolean, $beginDate: String, $endDate: String){ 
  users(username:$username){
    self{
      appointments(includeInactive: $includeInactive, beginDate: $beginDate, endDate: $endDate){
        appointmentId
        gecbAppointmentId
        inQuickerConfirmationNumber
        provider {
          displayName
        }
        facilityAddress {
          city
          countryCode
          line1
          line2
          stateOrProvince
          zipCode
        }
        appointmentTypeName
        facilityPhoneNumber
        appointmentStart
        appointmentStatus
        facilityName
      }
    }
  }
}`;

@Component({
  selector: 'app-user-appointments',
  templateUrl: './user-appointments.component.html',
  styleUrls: ['./user-appointments.component.scss']  
})

export class UserAppointmentsComponent implements OnInit {
  @Input() selectedUser: User;

  userAppointmentDataSource: MatTableDataSource<Appointment>;
  userAppointmentQuery: GraphQLQuery;
  availableAppointmentStatuses = AppointmentStatus;
  displayedColumns = ['appointmentId', 'gecbAppointmentId', 'providerName', 'facilityName', 'address', 'appointmentType', 'locationPhone', 'appointmentDate', 'appointmentStatus'];

  username: string;
  messageText: string;
  isValid: boolean;
  isLoading: boolean;
  selectedAppointmentStatus: number;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private ciamAuth: CiamAuth,
    private uiService: UiService    
  ) { }

  ngOnInit() {
    if (this.selectedUser) {
      this.route.params.subscribe(params => {
        this.username = params['user'];
      });
    }

    this.selectedAppointmentStatus = this.availableAppointmentStatuses.Upcoming;
    this.getUserAppointments();
  }

  async getUserAppointments(): Promise<void> {
      this.isLoading = true;

      this.userAppointmentQuery = {
          query: GET_APPOINTMENTS_BY_USERNAME_QUERY,
          variables: this.makeGetAppointmentsGraphQlVariables()
      }

      const result = await this.ciamAuth.httpApi.apiUsersQueryPost(this.userAppointmentQuery, true);
      const appointments = result && result.data && result.data.users && result.data.users.self && result.data.users.self.appointments;

      this.isLoading = false;
      if (!appointments) {
          return this.uiService.showErrors(result.errors)
      }

      const displayedAppointments = this.selectedAppointmentStatus == AppointmentStatus.Canceled
          ? appointments.filter(x => x.appointmentStatus == AppointmentStatus[AppointmentStatus.Canceled])
          : appointments;

      this.userAppointmentDataSource = new MatTableDataSource(displayedAppointments);

      this.isValid = this.userAppointmentDataSource.data.length > 0;
      this.messageText = this.isValid ? '' : 'No records to show!';
  }

  makeGetAppointmentsGraphQlVariables(): GetAppointmentsVariables {
    const status = this.selectedAppointmentStatus;
    const today = new Date();

    return {
      username: this.username,
      beginDate: status == AppointmentStatus.Upcoming ? today : MIN_DATE,
      endDate: status == AppointmentStatus.Past ? today : null,
      includeInactive: status == AppointmentStatus.All || status == AppointmentStatus.Upcoming
    }
  }
}

enum AppointmentStatus {
  Upcoming,
  All,
  Past,
  Canceled
}

interface GetAppointmentsVariables {
  username: string;
  beginDate: Date;
  endDate: Date;
  includeInactive: boolean;
}
