<div class="root">
  <mat-form-field>
    <mat-select placeholder="Domain" [(value)]="domainAssociation.domainName">
      <mat-option *ngFor="let domain of domains" [value]="domain.name">
        {{domain.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="startDate" placeholder="Start Date" [(ngModel)]="domainAssociation.beginDate">
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="endDate" placeholder="End Date" [(ngModel)]="domainAssociation.endDate">
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate></mat-datepicker>
  </mat-form-field>

  <div class="action-container" [ngSwitch]="pending">
    <button mat-raised-button color="primary" (click)="onAdd()" *ngSwitchCase="true">
      <mat-icon class="clickable" matTooltip="Add">add</mat-icon>
    </button>
    <button mat-raised-button color="warn" (click)="onRemove()" *ngSwitchCase="false">
      <mat-icon class="clickable" matTooltip="Remove">remove</mat-icon>
    </button>
  </div>
  <mat-error *ngIf="this.error">{{requiredError}}</mat-error>
  <mat-error *ngIf="this.saveError">{{requiredAddClickError}}</mat-error>
</div>
