<section class="page-content" *ngIf="user">

    <progress-bar [isLoading]="isLoading"></progress-bar>

    <button mat-raised-button color="primary" [hidden]="!canCreateBarGroup()" class="create-button-margin" (click)="createBarGroup()">
        <mat-icon>add</mat-icon>Create Bar Group
    </button>

    <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="groupId">
            <mat-header-cell *matHeaderCellDef fxFlex="20%">Group Id</mat-header-cell>
            <mat-cell *matCellDef="let row" fxFlex="20%">{{row.groupId}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="regionName">
            <mat-header-cell *matHeaderCellDef fxFlex="30%">Region Name</mat-header-cell>
            <mat-cell *matCellDef="let row" fxFlex="30%">{{row.barGroupRegion.regionName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="empiHubName">
            <mat-header-cell *matHeaderCellDef fxFlex="30%">Empi Name</mat-header-cell>
            <mat-cell *matCellDef="let row" fxFlex="30%">{{row.barGroupEmpiHub ? row.barGroupEmpiHub.empiHubName : ""}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef [hidden]="!canUpdateAndDeleteBarGroup()">Actions</mat-header-cell>
            <mat-cell *matCellDef="let barGroup" [hidden]="!canUpdateAndDeleteBarGroup()">
                <mat-icon (click)="editBarGroup(barGroup)" mattooltip="Edit" fxFlex="15%">border_color</mat-icon>
                <action-confirmation [actionConfirmation]="actionConfirmationData" (userResponse)="deleteBarGroup($event, barGroup)" fxFlex="15%">
                </action-confirmation>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>

    <div *ngIf="rowCount > 0">
        <mat-paginator #paginator [pageSize]="pageSize" [length]="rowCount" [pageSizeOptions]="pageSizeOptions" (page)="onPaginationChange($event)">
        </mat-paginator>
    </div>

    <div *ngIf="rowCount == 0">
        No Records to show!
    </div>

</section>