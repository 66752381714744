import { Component, OnInit, Inject } from '@angular/core';
import { CiamUser, CiamAuth } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateEmpiHubRequest } from '@dignity-health/ciam-auth/src/types/interfaces';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';

@Component({
  selector: 'empi-hub-create-update',
  templateUrl: './empi-hub-create-update.component.html',
  styleUrls: ['./empi-hub-create-update.component.scss']
})

export class EmpiHubCreateUpdateComponent {
  showLoader: boolean = false;
  empiHub: UpdateEmpiHubRequest;
  empiHubName = new FormControl(this.data ? this.data.empiHubName : '', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateEmpiHubRequest,
    public dialogRef: MatDialogRef<EmpiHubCreateUpdateComponent>,
    private ciamAuth: CiamAuth,
    private barGroupService: BarGroupService,
    private uiService: UiService) {
    this.empiHub = data ? data : null;
  }

  saveEMPIHub() {
    this.showLoader = true;

    if (this.empiHub) {
      if (this.empiHub.empiHubName == this.empiHubName.value.trim()) {
        this.showLoader = false;
        this.uiService.openSnackbar("A Empi Hub with name '" + this.empiHubName.value.trim() + "' already exists", 'Success');
        return;
      }

      this.updateEmpiHub();
    }
    else {
      this.createEmpiHub();
    }
  }

  updateEmpiHub() {
    this.empiHub.empiHubName = this.empiHubName.value.trim();
    this.ciamAuth.httpApiFacilities.apiAdminBargroupUpdateEmpihubPatch(this.empiHub)
      .then(response => {
        this.showLoader = false;

        if (response.isValid) {
          this.uiService.openSnackbar('EMPI Hub is successfully updated', 'Success');
          this.dialogRef.close();
        }
        else if (response.errors.length > 0) {
          this.uiService.openSnackbar(response.errors[0].message, 'Error');
        }
        this.barGroupService.getEMPIHubs();
      });
  }

  createEmpiHub() {
    this.ciamAuth.httpApiFacilities.apiAdminBargroupCreateEmpihubPost({ empiHubName: this.empiHubName.value.trim() })
      .then(response => {
        this.showLoader = false;

        if (response.isValid) {
          this.uiService.openSnackbar('EMPI Hub is successfully created', 'Success');
          this.dialogRef.close();
          this.barGroupService.getEMPIHubs();
        }
        else if (response.errors.length > 0) {
          this.uiService.openSnackbar(response.errors[0].message, 'Error');
        }
      });
  }
}

