
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CiamAuth} from '@dignity-health/ciam-auth';
import { environment } from '../../../environments/environment';
import { CiamUser } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { IAppState } from '../../types/state';
import { PortalSupportEvent } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { PagedResult } from '../../types/graphql';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { PortalLogViewerService } from 'app/services/portal-logs/portal-log-viewer';
import { Observable } from 'rxjs';

@Component({
  selector: 'support-log-viewer',
  templateUrl: './support-log-viewer.component.html',
  styleUrls: ['./support-log-viewer.component.scss']
})

export class SupportLogViewerComponent implements OnInit {
  isLoading: boolean = false;
  fromDate: Date;
  toDate: Date;
  username: string;
  errorCode: string;
  user: CiamUser;
  user$: Observable<CiamUser>;
  currentPage: number;
  pageSize: number;
  rowCount: number;
  pageSizeOptions: number[] = [5, 10, 20];
  pagedResult: PagedResult<PortalSupportEvent[]>;
  @ViewChild('paginator') paginator: MatPaginator;
  maxDate = new Date();

  dataSourcePortalLogs: MatTableDataSource<PortalSupportEvent>;
  displayedColumns = ["errorCode", "message", "username", "timestamp"];
  @ViewChild(MatTable) table: MatTable<PortalSupportEvent>;
  showAdvancedSearch: boolean = false;

  constructor(
    private router: Router,
    private uiService: UiService,
    private route: ActivatedRoute,
    private ciamAuth: CiamAuth,
    private portalLogViewerService: PortalLogViewerService,
    private store: Store<IAppState>) {
    this.user$ = store.select(s => s.user)
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.getUserName();
  }

  getUserName() {
    this.route.params.subscribe(params => {
      this.username = params['username'];
      this.performSearch();
    });
  }

  async refreshSupportEventData(){
    this.isLoading = true;
    const pagedPortalLogs: PagedResult<PortalSupportEvent[]> = await this.portalLogViewerService.getPortalLogs(this.currentPage, this.pageSize, this.username, this.errorCode, this.fromDate, this.toDate);
    try {
      this.dataSourcePortalLogs = new MatTableDataSource(pagedPortalLogs.results as PortalSupportEvent[]);
      this.rowCount = pagedPortalLogs.rowCount;
    } catch (errors) {
      return;
    } finally {
      this.isLoading = false;
    }
  }

  performSearch() {
    this.resetPagination();
    this.refreshSupportEventData();
  }

  resetPagination() {
    this.currentPage = 1;
    this.pageSize = 10;
    this.rowCount = 0;
  }

  onPaginationChange(pageEvent: PageEvent) {
    this.pageSize = pageEvent == null ? this.pageSize : pageEvent.pageSize;
    this.currentPage = pageEvent == null ? this.currentPage : pageEvent.pageIndex + 1;

    this.refreshSupportEventData();
  }

  formatStackTrace(traceJson: string): string {
    return JSON.stringify(JSON.parse(traceJson), null, 2);
  }

  getOktaAdminViewUserProfileUrl(oktaUserId): void {
    window.open(`${environment.oktaAdminInterfaceUrl}admin/user/profile/view/${oktaUserId}`, "_blank");
  }

  toggleAdvancedSearchControls() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    if (!this.showAdvancedSearch) {
      this.resetAdvancedSearchParams();
    }
  }
  
  clearSearch() {
    this.showAdvancedSearch = false;
    this.resetAdvancedSearchParams();
  }

  resetAdvancedSearchParams(): void {
    this.username = '';
    this.errorCode = '';
  }
}
