<section class="page-content" *ngIf="user">
    <div fxLayout="row" class="search-component"   fxLayoutGap="10px">
        <div fxFlex="100%" fxFlexAlign="left" class="align-left">
            <mat-form-field>
                <input matInput [matDatepicker]="fromDatePicker" placeholder="From date" name="fromDate" 
                      #startDate="ngModel"  [(ngModel)]="fromDate" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="toDatePicker" placeholder="To date" name="toDate" 
                  [(ngModel)]="toDate" [max]="maxDate" [min]="fromDate">
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
          <button class="search-button" mat-raised-button [color]="'primary'" (click)="performSearch()">
            <mat-icon>search</mat-icon> Search
          </button>
        </div>
      </div>
    
      <div fxLayout="row"  class="advanced-search">
          <div fxFlex>
            <a (click)="toggleAdvancedSearchControls()">Advanced Search</a>
          </div>
      </div>
     
        <div fxLayout="column" *ngIf="showAdvancedSearch" fxLayoutGap="20px">
            <div fxFlex="30%">
              <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field fxFlex="20%">
                    <input matInput type="text" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" placeholder="Username">
                  </mat-form-field>
                  <mat-form-field fxFlex="20%">
                    <input  matInput  type="text" [(ngModel)]="errorCode" [ngModelOptions]="{standalone: true}" placeholder="Error Code">
                  </mat-form-field>
              </div>
            </div>
        </div>

  <progress-bar [isLoading]="isLoading"></progress-bar>
  <div class="portal-logs-grid-container">
    <mat-table [dataSource]="dataSourcePortalLogs">
      <ng-container matColumnDef="errorCode">
        <mat-header-cell *matHeaderCellDef fxFlex="30%">Error Code</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="30%">{{row.errorCode}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="message">
        <mat-header-cell *matHeaderCellDef fxFlex="35%">Error Message</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="35%">{{row.message}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef fxFlex="25%">Username</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="25%">{{row.username}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef fxFlex="10%">Timestamp</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="10%">{{row.timestamp | utcDate | date:'medium'}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div *ngIf="rowCount > 0">
    <mat-paginator #paginator [pageSize]="pageSize" [length]="rowCount" [pageSizeOptions]="pageSizeOptions" (page)="onPaginationChange($event)">
    </mat-paginator>
  </div>
  <div *ngIf="rowCount == 0">
    No Records to show!
  </div>
</section>
