import { Injectable } from "@angular/core";
import { CiamAuth, Facility, ServiceResponse, ServiceResponseError, BarGroup, FacilityDomainInfo, UpdateFacilityRequest, CreateFacilityRequest, GraphQLRequest } from "@dignity-health/ciam-auth";
import { PagedResult, GraphQLResponse, GraphQLPagedResponse } from "../../types/graphql";
import { graphQLPagedResponseToServiceResponse, graphQLResponseToServiceResponse } from "../../types";
import { Store } from "@ngrx/store";
import { IAppState } from "app/types/state";
import { UpdateDomainsListing, UpdateBarGroupsListings } from "../../store/actions/info";
import { SortInfo, PageInfo } from "app/types/models";

const EXPORT_FACILITY_DATA_QUERY = `
{
  facilities {
    isActive
    facilityId
    schedulingLocationId
    friendlyFacilityName
    edwFacilityName
    domainName
    registrationSystem
    webAddress
    region
    registrationSystem
    groupId
    empiId
    latitude
    longitude
    addressLine1
    addressLine2
    city
    stateOrProvince
    zipCode
    countryCode
    barGroup {
      barGroupId
      empiId
      region
      groupId
    }
    images {
      imageId
      groupId
      url
      urlPath
      height
      width
      isDefaultImage
    }
    alternateNames {
      alternateFacilityId
      alternateFacilityName
    }
    domains {
      domainId
      beginDate
      endDate
      name
    }
  }
}
`;

const GET_PAGED_FACILITIES_QUERY: string = `
query getAllFacilitiesPaged($includeInactive: Boolean,
                            $searchTerm: String,
                            $currentPage: Int,
                            $pageSize: Int,
                            $sortColumn: String,
                            $sortOrder: String) {
                            pagedFacilities(
                              includeInactive: $includeInactive,
                              searchTerm: $searchTerm,
                              pageInfo: {
                                currentPage: $currentPage,
                                pageSize: $pageSize},
                              sortInfo: {
                                sortColumn: $sortColumn,
                                sortOrder: $sortOrder
                                })
{
    currentPage
    pageCount
    pageSize
    rowCount
    results {
      facilityId
      isActive
      edwFacilityName
      schedulingLocationId
      friendlyFacilityName
      domainName
      registrationSystem
      webAddress
      addressLine1
      addressLine2
      city
      stateOrProvince
      zipCode
      latitude
      longitude
      barGroup {
        barGroupId
        empiId
        region
        groupId
      }
      alternateNames {
        alternateFacilityId
        alternateFacilityName
      }
      domains {
        domainId
        name
        isActive
        beginDate
        endDate
      }
    }
  }
} 
`;

const GET_ALL_FACILITIES_RELATED_INFO_QUERY = `
{
  domains {
    domainId
    name
  }
  barGroups {
    barGroupId
    groupId
    region
    empiId
  }
} 
`;

@Injectable()
export class FacilitiesService {
    constructor(private ciamAuth: CiamAuth, private store: Store<IAppState>) { }

  getPagedFacilities(includeInactive: boolean, searchTerm: string, pageInfo: PageInfo, sortInfo: SortInfo): Promise<ServiceResponse<PagedResult<Facility[]>>> {
        const query = {
            query: GET_PAGED_FACILITIES_QUERY,
            variables: {
                currentPage: pageInfo.currentPage,
                pageSize: pageInfo.pageSize,
                sortColumn: sortInfo.sortColumn,
                sortOrder: sortInfo.sortOrder,
                searchTerm: searchTerm,
                includeInactive: includeInactive
            }
        };

    return this.ciamAuth.httpFacilitiesService.graphQlQuery("graphql/query", query)
            .then((response: GraphQLPagedResponse<{ pagedFacilities: PagedResult<Facility[]> }>) => {
                return graphQLPagedResponseToServiceResponse(response, r => r.pagedFacilities)
            });
    }

    getAllFacilitiesRelatedInfo(updateGlobally: boolean = false): Promise<ServiceResponse<{ domains: FacilityDomainInfo[], barGroups: BarGroup[] }>> {
        const query = {
            query: GET_ALL_FACILITIES_RELATED_INFO_QUERY
        };

      return this.ciamAuth.httpFacilitiesService.graphQlQuery("graphql/query", query)
          .then((response: GraphQLResponse<{ domains: FacilityDomainInfo[], barGroups: BarGroup[] }>) => {
                return graphQLResponseToServiceResponse(response, r => r);
            })
            .then(response => {
                if (response.isValid && updateGlobally) {
                    const auxInfo = response.result;
                    this.store.dispatch(new UpdateDomainsListing(auxInfo.domains));
                    this.store.dispatch(new UpdateBarGroupsListings(auxInfo.barGroups));
                }

                return response;
            });
    }

    createFacility(request: CreateFacilityRequest): Promise<ServiceResponse<Facility>> {
        return this.ciamAuth.httpApiFacilities.apiAdminFacilitiesPost(request);
    }

    updateFacility(id: number, request: UpdateFacilityRequest): Promise<ServiceResponse<Facility>> {
      return this.ciamAuth.httpApiFacilities.apiAdminFacilitiesByIdPatch(id, request);
    }

    exportFacilityData(): Promise<ServiceResponse<Facility[]>> {
        const query = { query: EXPORT_FACILITY_DATA_QUERY };

      return this.ciamAuth.httpFacilitiesService.graphQlQuery("graphql/query", query)
            .then((response: GraphQLResponse<Facility[]>) => graphQLResponseToServiceResponse(response, r => r));
    }
}
