import { Component, ViewChild } from '@angular/core';
import { UiService } from '../../../services/ui/ui.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { PortalInvitation } from '@dignity-health/ciam-auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PatientSearchParameters } from '../../../types/patient-portals-invitation-datasource';
import { GraphQLQuery, PagedResult } from '../../../types/graphql';
import { ResendModalComponent } from '../../auth/resend-modal/resend-modal.component';
import { ExpireModalComponent } from '../../expire-modal/expire-modal.component';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { WarningDialogComponent } from '../../../shared/warning-dialog/warning-dialog.component';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE, MAX_DAYS_RANGE_ERROR_MESSAGE, INVALID_DATE } from 'app/types/constants';
import * as moment from 'moment';
import { calculateDateRange, DATE_REGEX } from '../../../types/helpers/dateValidation';

const GET_INVITATIONS_QUERY = `query getInvitationsFromApi($patientFirstName: String, $patientLastName: String,
      $recipientEmail: String, $patientDateOfBirth: String, $domainName: String, $createdDateStart:String,$createdDateEnd:String,
      $statusId: Int, $pageSize: Int, $currentPage: Int){
      searchPortalInvitations(pageInfo: { pageSize: $pageSize currentPage: $currentPage},
      query:{patientFirstName: $patientFirstName, patientLastName: $patientLastName, recipientEmail: $recipientEmail,
            patientDateOfBirth: $patientDateOfBirth,domainName: $domainName,createdDateStart: $createdDateStart,
            createdDateEnd:$createdDateEnd, statusId: $statusId})
      {
      pageSize
      currentPage
      firstRowOnPage
      lastRowOnPage
      pageCount
      rowCount
      results {
          invitationId
          invitationSource
          verificationCode
          patientFirstName
          patientLastName
          recipientEmail
          patientDateOfBirth
          domainName
          status
          dateClaimed
          createdDate
          dateSent
          modifiedDate
      }
      }}`;

@Component({
  selector: 'app-cerner-invitations',
  templateUrl: './cerner-invitations.component.html',
  styleUrls: ['./cerner-invitations.component.scss']
})
export class CernerInvitationsComponent {
  form: FormGroup;
  invitations: PortalInvitation;
  // tslint:disable-next-line:max-line-length
  patientSearchParameters: PatientSearchParameters = { firstName: '', lastName: '', recipientEmail: '', dateOfBirth: null, statusId: null, domain: '', createdDateStart: null, createdDateEnd: null };

  maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
  maxDaysErrorMessage = MAX_DAYS_RANGE_ERROR_MESSAGE;
  invalidDateMessage = INVALID_DATE;
  minDate = MIN_DATE;
  maxDate = new Date();
  rowCount = 0;
  currentPage = 1;
  pageSize = 10;
  searchInvitationQuery: GraphQLQuery;
  showNoResultMessage = false;
  isLoading: Boolean = false;
  createdDate: any;

  statusTypes = [
    { value: -1, name: 'All', color: '#000000' },
    { value: 1, name: 'Pending', color: '#ea7931' },
    { value: 3, name: 'Confirmed', color: 'green' },
    { value: 4, name: 'Expired', color: '#ff0000' },
    { value: 5, name: 'RevokedAoM', color: '#ff7200' },
    { value: 6, name: 'Revoked', color: '#ffa100' }
  ];

  @ViewChild(MatTable)
  table: MatTable<PortalInvitation>;

  displayedColumns = [
    'patientFirstName',
    'patientLastName',
    'patientDateOfBirth',
    'invitationSource',
    'verificationCode',
    'patientRecipientEmail',
    'domainName',
    'status',
    'createdDate',
    'dateSent',
    'claimedDate',
    'expiredDate',
    'actions'
  ];
  dataSource: MatTableDataSource<PortalInvitation>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  createdDateStart: Date;
  createdDateEnd: Date;
  daysDiff: number;
  dateFormat = 'MM/DD/YYYY';

  constructor(private ciamAuth: CiamAuth,
    private uiService: UiService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      'patientFirstName': [''],
      'patientLastName': [''],
      'recipientEmail': [''],
      'patientDateOfBirth': [null],
      'domain': [''],
      'status': [null],
      'createdDateRange': [null]
    });
    this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data));
  }

  dateRangeClear($event) {
    if ($event.clear) {
      this.createdDate = '';
      setTimeout(function () {
        document.getElementById('createdDateRange').click();
      }, 10);
    }
  }

  dateRangeChange($event) {
    const searchDate = $event.startDate + ' - ' + $event.endDate;
    this.manualDateChange(searchDate);
  }

  manualDateChange(searchDate: string) {
    this.createdDateStart = null;
    this.createdDateEnd = null;
    this.form.controls.createdDateRange.updateValueAndValidity();

    if (searchDate == null || searchDate === '') {
      return;
    }

    const dateArray = searchDate.split('-');

    for (let index = 0; index < dateArray.length; index++) {
      if (!(dateArray[index].trim()).match(DATE_REGEX)) {
        this.form.controls.createdDateRange.setErrors({ 'dateformat': true });
        return;
      }
    }

    this.createdDateStart = new Date(moment(dateArray[0]).format(this.dateFormat));
    this.createdDateEnd = dateArray.length === 1 ?
      new Date(moment(searchDate).format(this.dateFormat)) :
      new Date(moment(dateArray[1]).format(this.dateFormat));

    this.daysDiff = calculateDateRange(this.createdDateStart, this.createdDateEnd);
    this.createdDate = searchDate;

    if (this.daysDiff > 91) {
      setTimeout(() => this.form.controls.createdDateRange.setErrors({ 'valid': true }));
      return;
    }
  }

  onFormValuesChanged(data: any) { }

  getInvitations(pageEvent?: PageEvent): void {
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;
    this.pageSize = pageEvent ? pageEvent.pageSize : this.pageSize;
    this.currentPage = pageEvent ? pageEvent.pageIndex + 1 : 1;

    this.searchInvitationQuery = {
      query: this.constructQuery(),
      variables: {
        'patientFirstName': this.patientSearchParameters.firstName,
        'patientLastName': this.patientSearchParameters.lastName,
        'recipientEmail': this.patientSearchParameters.recipientEmail,
        'patientDateOfBirth': this.patientSearchParameters.dateOfBirth,
        'domainName': this.patientSearchParameters.domain,
        'createdDateStart': this.createdDateStart ? new Date(this.createdDateStart).toISOString() : null,
        'createdDateEnd': this.createdDateEnd ? new Date(this.createdDateEnd).toISOString() : null,
        'statusId': this.patientSearchParameters.statusId,
        'pageSize': this.pageSize,
        'currentPage': this.currentPage
      }
    };

    // Set the paginator's pageindex back to 0, if searching on page 1
    if (this.currentPage === 1 && this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.searchInvitationQuery)
      .then(results => {
        const hasSummaries = results && results.data && results.data.searchPortalInvitations;
        if (!hasSummaries) {
          this.isLoading = false;
          // tslint:disable-next-line:max-line-length
          return this.openWarningDialog('There is some issue in fetching the Cerner Invitations. Please contact the technical support team.');
        }

        const pagedResult = <PagedResult<PortalInvitation>>(results.data.searchPortalInvitations);
        this.dataSource = new MatTableDataSource<PortalInvitation>(<PortalInvitation[]>(results.data.searchPortalInvitations.results));
        this.rowCount = pagedResult.rowCount;
        this.pageSize = pagedResult.pageSize;
        this.currentPage = pagedResult.currentPage;
        this.showNoResultMessage = this.rowCount === 0;
        this.isLoading = false;
      });
  }

  openWarningDialog(message) {
    this.dialog.open(WarningDialogComponent, {
      data: {
        dialogMessage: message
      }
    });
  }

  openResendModal(data: PortalInvitation) {
    this.dialog.open(ResendModalComponent, {
      height: '250px',
      width: '300px',
      data: data
    });
  }

  openExpireModal(data: PortalInvitation) {
    this.dialog.open(ExpireModalComponent, {
      height: '200px',
      width: '300px',
      data: data
    });
  }

  statusColor(status: string): string {
    const selectedResult = this.statusTypes.find(item => item.name.toLowerCase().indexOf(status) !== -1);
    return selectedResult ? selectedResult.color : '#000000';
  }

  calculateExpirationDate(portal: PortalInvitation): Date {
    return portal.status.toLocaleString().toLocaleLowerCase() === 'expired' ? new Date(portal.modifiedDate) : null;
  }

  constructQuery(): string {
    if (this.form) {
      this.patientSearchParameters.firstName = this.form.value.patientFirstName;
      this.patientSearchParameters.lastName = this.form.value.patientLastName;
      this.patientSearchParameters.recipientEmail = this.form.value.recipientEmail;
      this.patientSearchParameters.domain = this.form.value.domain;
      this.patientSearchParameters.dateOfBirth = this.uiService.formatDate(this.form.value.patientDateOfBirth);
      this.patientSearchParameters.createdDateStart = this.uiService.formatDate(this.form.value.createdDateStart);
      this.patientSearchParameters.createdDateEnd = this.uiService.formatDate(this.form.value.createdDateEnd);
      this.patientSearchParameters.statusId = this.form.value.status !== -1 ? this.form.value.status : null;
    }

    return GET_INVITATIONS_QUERY;
  }
}