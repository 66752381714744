import { Injectable, InjectionToken } from '@angular/core';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { User } from '@dignity-health/ciam-auth/src/types/interfaces';
import { GraphQLPagedResponse, PagedResult, GraphQLQuery } from './graphql';

import * as _ from 'lodash';

export const TOKEN = new InjectionToken('users-datasource');

@Injectable()
export class UsersDataSource {
  constructor(private ciamAuth: CiamAuth) { }

  searchUsers(searchUsersQuery: GraphQLQuery) {
    return this.ciamAuth.user.graphQlQuery(searchUsersQuery);
  }

  searchUserPortals(query: GraphQLQuery): any {
    return this.ciamAuth.user.graphQlQuery(query);
  }

  searchPatients(searchPatientsQuery: GraphQLQuery) {
      return this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(searchPatientsQuery);
  }
        
  getSelfEnrollmentAllowedStatus(query: GraphQLQuery): any {
    return this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(query);
  }

  disconnect(): void { }
}
