import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { ResetUserPasswordStatusComponent, ResetUserPasswordStatus } from './reset-user-password-status.component';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { UiService } from 'app/services/ui/ui.service';
import { RESET_PASSWORD_ERROR_MESSAGE, RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_MESSAGE, RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_CODE } from 'app/types/constants';

@Component({
    selector: 'app-reset-user-password-dialog',
    templateUrl: './reset-user-password-dialog.component.html',
    styleUrls: ['./reset-user-password-dialog.component.scss']
})

export class ResetUserPasswordDialogComponent {
    username: string;
    showWithSecuritySpinner: boolean = false;
    
    constructor(private ciamAuth: CiamAuth, public dialog: MatDialog, private uiService: UiService, @Inject(MAT_DIALOG_DATA) public data: string) {
        this.username = data;
    }

    dialogData: ResetUserPasswordStatus = <ResetUserPasswordStatus>{
        message: ''
    };    

    withSecurityQuestionActionConfirmationData: IActionConfirmation = {
        matIcon: "lock",
        actionButtonText: "Reset with Security",
        modalTitle: "Confirmation!",
        modalInstruction: "Are you sure you want to reset the password with a security challenge question?",
        cancelButtonText: "Cancel",
        okButtonText: "Yes",
        isAdvanceConfirmation: false,
        actionButtonClass: 'mat-raised-button'
    }

    async resetUserPassword() {
        try {
            this.showWithSecuritySpinner = true;
            const response = await this.ciamAuth.httpApi.apiAdminUsersResetUserPasswordPost(this.username);
            var message = '';

            message = response.isValid ? 'An email has been sent to the user for resetting password!'
                : response.errors.filter(e => e.code == RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_CODE).length > 0
                    ? RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_MESSAGE
                    : RESET_PASSWORD_ERROR_MESSAGE;

            this.dialogData.message = message;            

            this.dialog.open(ResetUserPasswordStatusComponent, {
                data: this.dialogData
            });
        } catch (err) {
            this.uiService.openSnackbar(RESET_PASSWORD_ERROR_MESSAGE);
        }
        this.showWithSecuritySpinner = false;
    }

    resetUserPasswordResponse(confirmationValue: boolean) {
        if (confirmationValue) {
            this.resetUserPassword();
        }
    }  
}
