import { Component, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
  })
  export class DateRangePickerComponent {

     startDate: Date;
     endDate: Date;
     daysDiff: number;
     dateFormat: String = 'MM/DD/YYYY';
     dateRang:any;
     @Output() dateRangeChange: EventEmitter<any> = new EventEmitter();
     @Output() dateRangeClear: EventEmitter<any> = new EventEmitter();

    ranges: any = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    changeDateRange($event) {
      if ($event.startDate == null && $event.endDate == null) {
        this.dateRangeClear.emit({ clear: true });
        return;
      }

      this.startDate = $event.startDate != null ? $event.startDate.format(this.dateFormat) : null;
      this.endDate = $event.endDate != null ? $event.endDate.format(this.dateFormat) : this.startDate;
      this.dateRangeChange.emit({
        startDate: this.startDate,
        endDate: this.endDate
      });
  }

  openCalendar() {
    setTimeout(function(){
      document.getElementById('createdDateRange').click();
    }, 60);
  }
}
