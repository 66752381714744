<div>

    <h1 mat-dialog-title>Bar Group Details</h1>

    <form (ngSubmit)="onFormSubmit()" [formGroup]="form">

        <div>
            <form-input [validationErrors]="validationErrors" [name]="'groupId'">
                <mat-form-field>
                    <input matInput type="text" placeholder="Group Id" formControlName="groupId" />
                </mat-form-field>
            </form-input>
        </div>

        <div>
            <form-input [validationErrors]="validationErrors" [name]="'regionName'">
                <mat-form-field>
                    <mat-select placeholder="Select Region" formControlName="regionName">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let region of regions" [value]="region.regionId">
                            {{region.regionName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form-input>
        </div>

        <div>
            <form-input [validationErrors]="validationErrors" [name]="'empiHubId'">
                <mat-form-field>
                    <mat-select placeholder="Select Empi Hub" formControlName="empiHubId">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let empiHub of empiHubs" [value]="empiHub.empiHubId">
                            {{empiHub.empiHubName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form-input>
        </div>

        <div mat-dialog-actions>
            <button mat-button matDialogClose color="accent">Cancel</button>
            <button mat-button type="submit" color="primary">Save</button>
            <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="barGroup-loader-margin"  [diameter]="30"></mat-spinner>
        </div>

    </form>

</div>