import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { IAppState } from 'app/types';
import { UpdatePatientSearch, UpdateCernerSearch, UpdateUserSearch } from 'app/store/actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-search-type',
  templateUrl: './search-type.component.html',
  styleUrls: ['./search-type.component.scss']
})
export class SearchTypeComponent implements OnInit {

  @Output() onSearchTypeChanged = new EventEmitter();
  searchTypeSelected: string;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    if (localStorage.getItem("searchType") != null) {
      this.searchTypeSelected = JSON.parse(localStorage.getItem('searchType'));
    }
    else {
      this.searchTypeSelected = "MyHome";
    }
  }

  searchTypeChanged($event: MatRadioButton) {
    this.store.dispatch(new UpdateUserSearch(null));
    this.store.dispatch(new UpdatePatientSearch(null));
    this.store.dispatch(new UpdateCernerSearch(null));

    localStorage.setItem("searchType", JSON.stringify($event.value));
    this.onSearchTypeChanged.emit($event.value);
  }
}
