import { UiServiceErrorsDialogComponent } from './services/ui/ui.service';
import {
  MatProgressSpinnerModule
} from '@angular/material/progress-spinner';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { CiamAuth } from '@dignity-health/ciam-auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TableModule } from 'primeng/table';
// import { SharedModule } from 'primeng';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { user, facility, terms, userSearch, parentUser } from './store/reducers';
import { ciamAuthFactory } from './app.factories';
import { TermsAndConditionsDataSource } from './types';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginNavbarComponent } from './components/auth/login-navbar/login-navbar.component';
import { FormInputComponent } from './components/form-control/form-input/form-input.component';
import { MaterialFormInputComponent } from './components/form-control/material-form-input/material-form-input.component';
import { FacilitiesComponent } from './components/facility/facilities/facilities.component';
import { SupportLogViewerComponent } from './components/support-log-viewer/support-log-viewer.component';
import { UiService } from 'app/services/ui/ui.service';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import { InstructionService } from 'app/services/instructions/instructions-service';
import { PortalLogViewerService } from 'app/services/portal-logs/portal-log-viewer';
import { CernerInvitationsComponent } from './components/cerner/cerner-invitations/cerner-invitations.component';
import { PatientPortalsInvitationDataSource } from 'app/types/patient-portals-invitation-datasource';
import { ResendModalComponent } from './components/auth/resend-modal/resend-modal.component';
import { ExpireModalComponent } from './components/expire-modal/expire-modal.component';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './components/auth/login/login.component';
import { UsersComponent } from './components/user/users/users.component';
import { UsersDataSource } from 'app/types/users-datasource';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ResetUserPasswordComponent } from './components/auth/reset-user-password/reset-user-password.component';
import { ResetUserPasswordDialogComponent } from './components/auth/reset-user-password/reset-user-password-dialog.component';
import { ResetUserPasswordStatusComponent } from './components/auth/reset-user-password/reset-user-password-status.component';
import { TemporaryUserPasswordDialogComponent } from './components/auth/temporary-user-password/temporary-user-password-dialog.component';
import { TemporaryUserPasswordStatusComponent } from './components/auth/temporary-user-password/temporary-user-password-status.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { SynonymsComponent } from './components/provider-search/synonyms/synonyms.component';
import { AzureSearchService } from './services/azure-search/azure-search-service';
import { ScoreAndFieldsService } from './services/azure-search/score-and-fields-service';

import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { UserProfileImageSectionComponent } from './components/user/user-profile-image-section/user-profile-image-section.component';
import { UserProfileOverviewComponent } from './components/user/user-profile-overview/user-profile-overview.component';
import { UserProfileGroupsComponent } from './components/user/user-profile-groups/user-profile-groups.component';
import { UserAssociatedAccountProfileComponent } from 'app/components/user/user-associated-account-profile/user-associated-account-profile.component';
import { UsersSearchComponent } from './components/user/users-search/users-search.component';
import { EnumKeyValueListPipe } from './pipes/enum-key-value-list/enum-key-value-list.pipe';
import { UserManageAssociatedAccountComponent } from 'app/components/user/user-manage-associated-account/user-manage-associated-account.component';
import { FacilityAddressPipe } from './pipes/facility-address/facility-address.pipe';
import { FacilitiesService } from './services/facilities/facilities-service';
import { FacilitiesQuickEditModalComponent } from './components/facility/facilities-quick-edit-modal/facilities-quick-edit-modal.component';
import { FacilityDomainAssociationComponent } from './components/facility/facility-domain-association/facility-domain-association.component';
import { info } from './store/reducers/info';
import { FacilitiesCreateComponent } from './components/facility/facilities-create/facilities-create.component';
import { ActivePipe } from './pipes/active/active.pipe';
import { FacilitiesCreateUpdateComponent } from './components/facility/facilities-create-update/facilities-create-update.component';
import { AppSharedModule } from './shared/shared.module';
import { AngularSplitModule } from 'angular-split';
import { FlagGuard } from './guards/flag-guard/flag-guard.guard';
import { environment } from 'environments/environment';
import { RouteDisabledComponent } from './components/route-disabled/route-disabled.component';
import { OktaSupportGroupsService } from './services/okta-group/okta-support-group.service';
import { UnauthorizedComponent } from './components/auth/unauthorized/unauthorized.component';
import { UtcDatePipe } from './pipes/utc-date/utc-date.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AppDateAdapter } from 'app/common/app-date-adapter';
import { RegionsComponent } from './components/region/regions/regions.component';
import { RegionsDetailsComponent } from './components/region/regions-details/regions-details.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { EmpiHubComponent } from './components/empi/empi-hub/empi-hub.component';
import { EmpiHubCreateUpdateComponent } from './components/empi/empi-hub-create-update/empi-hub-create-update.component';
import { BarGroupFacilitiesComponent } from './components/bar-group/bargroup-facilities/bargroup-facilities.component';
import { BarGroupsComponent } from './components/bar-group/bar-groups/bar-groups.component';
import { BarGroupsCreateUpdateComponent } from './components/bar-group/bar-groups-create-update/bar-groups-create-update.component';
import { ScoreComponent } from './components/provider-search/scores/score.component';

import { UserAppointmentsComponent } from './components/user/user-appointments/user-appointments.component';

import { CustomRouteReuseStrategy } from 'app/route-reuse-strategy.component';
import { UpdateUsernameComponent } from './components/user/update-username/update-username.component';
import { PatientsComponent } from './components/patient/patients/patients.component';
import { SearchTypeComponent } from './components/search-type/search-type.component';
import { PatientsSearchComponent } from './components/patient/patients-search/patients-search.component';
import { UsersAndPatientsComponent } from './components/user/users-and-patients/users-and-patients.component';
import { UserPatientDependentsDetailComponent } from './components/user/user-patient-dependents-detail/user-patient-dependents-detail.component';
import { PatientEnrollmentsComponent } from './components/patient/patient-enrollments/patient-enrollments.component';
import { PatientEnrollmentsSearchComponent } from './components/patient/patient-enrollments-search/patient-enrollments-search.component';
import { PatientEnrollmentErrorsComponent } from './components/patient/patient-enrollment-errors/patient-enrollment-errors.component';
import { PortalAccessService } from './services/portal-access/portal-access';
import { PhoneNumberPipe } from './pipes/phone-number/phone-number.pipe';
import { DomainWhitelistingComponent } from './components/domain/domain-whitelisting/domain-whitelisting.component';
import { PatientFacilitiesVisitComponent } from './components/patient/patient-facilities-visit/patient-facilities-visit.component';
import { UserPropertiesComponent } from './components/user/user-properties/user-properties.component';
import { AlertSettingsComponent } from './components/alerts/alert-settings/alert-settings.component';
import { SiteSettingService } from './services/site-settings/site-settings-service';
import { AlertSettingUpdateComponent } from './components/alerts/alert-setting-update/alert-setting-update.component';
import { FilterUserLevelPipe } from './pipes/filterLevel/filterLevel';
import { CernerEnvironmentConfigsComponent } from './components/cerner-environment-configs/cerner-environment-configs.component';
import { CernerSSOConfigService } from './services/cerner-sso-config/cerner-sso-config';

import { ResetOptionsDialogComponent } from './components/reset-options-dialog/reset-options-dialog.component';
import { AuthModule, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { configureAuth } from './auth/angular-oauth-configuration';

const flags = environment.featureFlags;

@NgModule({
  declarations: [
    // Components
    AlertSettingsComponent,
    AlertSettingUpdateComponent,
    AppComponent,
    CernerInvitationsComponent,
    CernerEnvironmentConfigsComponent,
    DomainWhitelistingComponent,
    EmpiHubComponent,
    EmpiHubCreateUpdateComponent,
    ExpireModalComponent,
    FacilitiesComponent,
    FacilitiesCreateComponent,
    FacilitiesCreateUpdateComponent,
    FacilityDomainAssociationComponent,
    FacilitiesQuickEditModalComponent,
    FormInputComponent,
    HomeComponent,
    InstructionsComponent,
    LoginComponent,
    LoginNavbarComponent,
    UserManageAssociatedAccountComponent,
    MaterialFormInputComponent,
    NotFoundComponent,
    ResendModalComponent,
    ResetUserPasswordComponent,
    RouteDisabledComponent,
    ResetUserPasswordStatusComponent,
    ResetUserPasswordDialogComponent,
    RegionsComponent,
    RegionsDetailsComponent,
    SearchTypeComponent,
    SupportLogViewerComponent,
    TemporaryUserPasswordStatusComponent,
    TemporaryUserPasswordDialogComponent,
    UiServiceErrorsDialogComponent,
    UsersComponent,
    UserDetailsComponent,
    UserAssociatedAccountProfileComponent,
    UserProfileImageSectionComponent,
    UserProfileOverviewComponent,
    UserProfileGroupsComponent,
    UsersSearchComponent,
    UnauthorizedComponent,
    ProgressBarComponent,
    PatientsComponent,
    PatientsSearchComponent,
    PatientEnrollmentsComponent,
    PatientEnrollmentsSearchComponent,
    PatientEnrollmentErrorsComponent,
    PatientFacilitiesVisitComponent,
    ProgressBarComponent,
    BarGroupFacilitiesComponent,
    BarGroupsComponent,
    BarGroupsCreateUpdateComponent,
    SynonymsComponent,
    UserAppointmentsComponent,
    UsersAndPatientsComponent,
    UserPatientDependentsDetailComponent,
    UpdateUsernameComponent,
    UserPropertiesComponent,
    ScoreComponent,
    ResetOptionsDialogComponent,

    // Pipes
    ActivePipe,
    CapitalizePipe,
    EnumKeyValueListPipe,
    FacilityAddressPipe,
    SafeHtmlPipe,
    UtcDatePipe,
    PhoneNumberPipe,
    FilterUserLevelPipe
  ],
  imports: [
    // Angular    
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    //Shared
    AppSharedModule,

    // // primeng
    // TableModule,
    // SharedModule,

    // Misc
    AngularSplitModule.forRoot(),

    // Angular Material
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    // ng-bootstrap
    //NgbModule.forRoot(),
    NgbModule,
    // ngrx
    StoreModule.forRoot({
      info,
      user,
      facility,
      terms,
      parentUser,
      userSearch
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 5
    }),

    // Routing
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'bargroup-facilities', component: BarGroupFacilitiesComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.barGroup.bargroupFacilities.create } },
      { path: 'facilities', component: FacilitiesComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.facilities.list } },
      { path: 'facilities/create', component: FacilitiesCreateComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.facilities.create } },
      { path: 'support-log-viewer', component: SupportLogViewerComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.search } },
      { path: 'support-log-viewer/view/:username', component: SupportLogViewerComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.search } },
      { path: 'users', component: UsersAndPatientsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.search } },
      { path: 'users/view', component: UserDetailsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.view } },
      { path: 'cerner-invitations', component: CernerInvitationsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.patientPortals.cerner.invitations.list } },
      { path: 'bargroups/list', component: BarGroupsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.barGroup.list } },
      { path: 'regions', component: RegionsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.barGroup.region.list } },
      { path: 'instructions', component: InstructionsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.instructions.create } },
      { path: 'empi-hub', component: EmpiHubComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.barGroup.empihub.list } },
      { path: 'patient/enrollments', component: PatientEnrollmentsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.enrollments.list } },
      { path: 'patient/enrollment-errors/:id', component: PatientEnrollmentErrorsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.enrollments.view } },
      { path: 'settings/domain-whitelisting', component: DomainWhitelistingComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.siteSettings.domainWhitelisting.create } },
      { path: 'facilitiesVisit/:id', component: PatientFacilitiesVisitComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.facilitiesVisit.view } },
      { path: 'alerts', component: AlertSettingsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.siteSettings.alerts.create } },
      { path: 'cernerSSODashboard', component: CernerEnvironmentConfigsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.developersOptions.cernerSSODashboard.view } },
      { path: 'providersearch/synonyms', component: SynonymsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.azureSearch.providerSearch.view } },
      { path: 'providersearch/scores', component: ScoreComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.azureSearch.scoreAndFields.create } },

      { path: 'route-disabled', component: RouteDisabledComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '', redirectTo: '/users', pathMatch: 'full' },
      { path: '**', component: NotFoundComponent },
    ], { enableTracing: false, useHash: environment.useHash }),
    AuthModule.forRoot()
  ],
  entryComponents: [
    AlertSettingUpdateComponent,
    ExpireModalComponent,
    EmpiHubCreateUpdateComponent,
    FacilitiesQuickEditModalComponent,
    ResendModalComponent,
    ResetUserPasswordStatusComponent,
    ResetUserPasswordDialogComponent,
    TemporaryUserPasswordStatusComponent,
    TemporaryUserPasswordDialogComponent,
    UiServiceErrorsDialogComponent,
    RegionsDetailsComponent,
    BarGroupsCreateUpdateComponent,
    UpdateUsernameComponent,
    ResetOptionsDialogComponent,
  ],
  providers: [
    AuthGuard,
    FlagGuard,
    DatePipe,
    BarGroupService,
    FacilitiesService,
    InstructionService,
    PortalLogViewerService,
    PatientPortalsInvitationDataSource,
    SiteSettingService,
    TermsAndConditionsDataSource,
    OktaSupportGroupsService,
    AzureSearchService,
    ScoreAndFieldsService,
    UiService,
    UsersDataSource,
    {
      provide: CiamAuth,
      useFactory: ciamAuthFactory
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    PortalAccessService,
    CernerSSOConfigService,
    OidcSecurityService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
   
  }
}
