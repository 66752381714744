import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { UiService } from 'app/services/ui/ui.service';
import { TEMP_PASSWORD_ERROR_MESSAGE } from 'app/types/constants';
import { TemporaryUserPasswordStatus, TemporaryUserPasswordStatusComponent } from './temporary-user-password-status.component';

@Component({
    selector: 'app-temporary-user-password-dialog',
    templateUrl: './temporary-user-password-dialog.component.html',
    styleUrls: ['./temporary-user-password-dialog.component.scss']
})

export class TemporaryUserPasswordDialogComponent {
    username: string;
    showTemporaryPasswordSpinner: boolean = false;

    constructor(private ciamAuth: CiamAuth, public dialog: MatDialog, private uiService: UiService, @Inject(MAT_DIALOG_DATA) public data: string) {
        this.username = data;
    }

    temporaryPasswordDialogData: TemporaryUserPasswordStatus = <TemporaryUserPasswordStatus>{
        title: 'Temporary Password!',
        message: '',
        password: ''
    };

    temporaryPasswordActionConfirmationData: IActionConfirmation = {
        matIcon: "watch_later",
        actionButtonText: "Temporary Password",
        modalTitle: "Confirmation!",
        modalInstruction: "Are you sure you want to generate a temporary password?",
        cancelButtonText: "Cancel",
        okButtonText: "Yes",
        isAdvanceConfirmation: false,
        actionButtonClass: 'mat-raised-button'
    }

    async temporaryPassword() {
        try {
            this.showTemporaryPasswordSpinner = true;
            const response = await this.ciamAuth.httpApi.apiAdminUsersGenerateTemporaryUserPasswordPost(this.username);
            var message = '';

            message = response.isValid ? 'A temporary password is generated successfully as below'
                : TEMP_PASSWORD_ERROR_MESSAGE;

            this.temporaryPasswordDialogData.message = message;
            this.temporaryPasswordDialogData.title = "Temporary Password!";
            this.temporaryPasswordDialogData.password = response.result.temporaryPassword;

            this.dialog.open(TemporaryUserPasswordStatusComponent, {
                data: this.temporaryPasswordDialogData
            });
        } catch (err) {
            this.uiService.openSnackbar(TEMP_PASSWORD_ERROR_MESSAGE);
        }
        this.showTemporaryPasswordSpinner = false;
    }

    temporaryPasswordResponse(confirmationValue: boolean) {
        if (confirmationValue) {
            this.temporaryPassword();
        }
    }
}
