import { Component, OnInit, ViewChild } from '@angular/core';
import { ScoreAndFieldsService } from 'app/services/azure-search/score-and-fields-service';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'app/services/ui/ui.service';
import { Router } from '@angular/router';
import { ScoringProfile, ScoringProfileWeight, SaveScoringProfileRequest } from '@dignity-health/ciam-auth/dist/src/types/provider-search/interfaces';
import { IActionConfirmation } from 'app/types/action-confirmation-model/action-confirmation-model';

@Component({
  selector: 'score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})

export class ScoreComponent implements OnInit {

  isSuperAdmin: boolean = false;
  hasResults: boolean;
  isLoading: boolean;
  rowCount: number = 0;
  scoreData: SaveScoringProfileRequest;
  scoringProfileWeight: ScoringProfileWeight;
  fieldName: string = '';
  weight: number = 0;

  displayedColumns = ["score", "weight", "action"];
  scoreDataSource: MatTableDataSource<ScoringProfile>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  fieldNames = [];

  constructor(private scoreAndFieldsService: ScoreAndFieldsService,
    private oktaGroupService: OktaSupportGroupsService,
    private uiService: UiService,
    private router: Router) {
    this.isSuperAdmin = this.oktaGroupService.isSuperAdmin;
  }

  ngOnInit() {

    if (!this.isSuperAdmin) {
      return this.router.navigate(['unauthorized']);
    }

    this.getScore();
    this.bindFieldNames();
  }

  actionConfirmationData: IActionConfirmation = {
    matIcon: 'delete',
    modalTitle: 'Delete Score Confirmation',
    modalInstruction: 'Are you sure you want to delete the selected Score field?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes',
    isAdvanceConfirmation: false
  }

  getScore(): void {

    this.isLoading = true;

    this.scoreAndFieldsService.getScoringProfile()
      .then((response) => {
        this.hasResults = true;
        if (response.result && response.result[0].weights) {
          this.scoreData = { scoringProfile: response.result[0] };
          this.scoreDataSource = new MatTableDataSource(response.result[0].weights);
          this.rowCount = response.result[0].weights.length;
          this.setPaginator();
        }
        this.isLoading = false;
      }).catch((e) => {
        this.uiService.showException(e);
        this.hasResults = false;
        this.isLoading = false;
      });
  }

  saveScore(): void {

    this.scoringProfileWeight = { fieldName: this.fieldName, weight: this.weight };
    this.scoreData.scoringProfile.weights.push(this.scoringProfileWeight);

    //call updateScore, because this method will call further an endpoint which is responsible for save/update/delete operation
    this.updateScore();
    this.fieldName = '';
    this.weight = 0;
  }

  updateScore(): void {

    this.isLoading = true;

    this.scoreAndFieldsService.updateScore(this.scoreData)
      .then(() => {
        this.isLoading = false;
        this.showMessage("Operation performed successfully.");
        this.getScore();
      }).catch((e) => {
        this.uiService.showException(e);
        this.isLoading = false;
      });
  }

  deleteScore(confirmationValue: boolean, scoringProfileWeight: ScoringProfileWeight): void {
    if (!confirmationValue) {
      return;
    }
    else {
      const index: number = this.scoreData.scoringProfile.weights.findIndex(item => item.fieldName == scoringProfileWeight.fieldName);

      if (index !== -1) {
        this.scoreData.scoringProfile.weights.splice(index, 1);
        this.updateScore();
      }
    }
  }

  bindFieldNames(): void {

    this.fieldNames = ['fullName', 'lastName', 'npi', 'officeNames',
      'practiceNames', 'hospitalNames', 'PrimarySpecialty', 'specialties',
      'procedure', 'conditions', 'insuranceCarriers', 'insurancePlans',
      'medicalGroupNames', 'philosophy', 'inMyOwnWords', 'degree',
      'medicalSchools', 'residencies', 'fellowships', 'internships',
      'practicums', 'certifications', 'awards'];
  }

  showMessage(message): void {
    this.uiService.openSnackbar(message, null, { duration: 2500 });
  }

  setPaginator() {
    this.scoreDataSource.paginator = this.paginator;
  }
}
