<div id="app_container" class="nav-container">
    <mat-toolbar color="primary" class="app-toolbar mat-elevation-z6">
        <button *ngIf="showMenu$ | async" mat-icon-button (click)="expandLeftMenu()">
      <mat-icon>menu</mat-icon>
    </button>
        <h1 class="app-name">
            <a [routerLink]="'/'">My Help</a>
        </h1>
        <span class="app-toolbar-spacer"></span>
        <login-navbar></login-navbar>
    </mat-toolbar>
    <as-split direction="horizontal" [gutterSize]="gutterSize">
        <as-split-area [size]="navWidth" flexOrder="0" class="app-sidenav-nav mat-elevation-z6">
            <mat-nav-list *ngIf="showMenu$ | async">
                <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showFacilitiesNavGroup()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>dashboard</mat-icon>Facilities
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item *ngIf="flags.navigation.facilities.list" [routerLink]="'/facilities'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">List</a>
                    <a mat-list-item *ngIf="flags.navigation.facilities.create" [routerLink]="'/facilities/create'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Create</a>
                </mat-expansion-panel>

                <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showBarGroupNavGroup()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>group</mat-icon>Bar Groups
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item *ngIf="flags.navigation.barGroup.list" [routerLink]="'/bargroups/list'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">List</a>
                    <a mat-list-item *ngIf="flags.navigation.barGroup.region" [routerLink]="'/regions'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Regions</a>
                    <a mat-list-item *ngIf="flags.navigation.barGroup.empihub" [routerLink]="'/empi-hub'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">EMPI Hubs</a>
                    <a mat-list-item *ngIf="flags.navigation.barGroup.bargroupFacilities.create" [routerLink]="'/bargroup-facilities'" routerLinkActive="active-link">Bar Group Facilities</a>
                </mat-expansion-panel>
                <a mat-list-item *ngIf="flags.navigation.patientPortals.cerner.invitations.list" [routerLink]="'/cerner-invitations'" routerLinkActive="active-link">
                    <mat-icon>send</mat-icon>Patient Portal Invitations
                </a>

                <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showAzureSearch()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>cloud</mat-icon>Azure Search
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item [routerLink]="'/providersearch/synonyms'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Provider Search Synonyms
          </a>
                    <a mat-list-item *ngIf="flags.navigation.azureSearch.scoreAndFields.create" [routerLink]="'/providersearch/scores'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Scores</a>
                </mat-expansion-panel>

                <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showSupportLog()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>search</mat-icon>Search Logs
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item *ngIf="flags.navigation.logs.search" [routerLink]="'/support-log-viewer'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
            Support logs
          </a>
                    <a mat-list-item *ngIf="flags.navigation.logs.enrollments.list" [routerLink]="'/patient/enrollments'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
            Patient Enrollment
          </a>
                </mat-expansion-panel>

                <a mat-list-item *ngIf="flags.navigation.users.search" [routerLink]="'/users'" routerLinkActive="active-link">
                    <mat-icon>group</mat-icon>Users
                </a>
                <a mat-list-item *ngIf="flags.navigation.instructions.create" [routerLink]="'/instructions'" routerLinkActive="active-link">
                    <mat-icon>insert_comment</mat-icon>Instructions
                </a>

                <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showDevelopersOptions()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>developer_mode</mat-icon>Developer Options
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item *ngIf="flags.navigation.developersOptions.cernerSSODashboard.view" [routerLink]="'/cernerSSODashboard'" routerLinkActive="active-link">
            Cerner Dashboard
          </a>
                </mat-expansion-panel>

                <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showSiteSettings()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>settings</mat-icon>Site Settings
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showMailSettings()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>email</mat-icon>Mail
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <a mat-list-item *ngIf="flags.navigation.siteSettings.domainWhitelisting.create" [routerLink]="'/settings/domain-whitelisting'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">E-mail Whitelist Configuration</a>
                    </mat-expansion-panel>
                    <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showMailSettings()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>add_alert</mat-icon>Alerts
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <a mat-list-item *ngIf="flags.navigation.siteSettings.alerts.create" [routerLink]="'/alerts'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Alerts</a>
                    </mat-expansion-panel>
                </mat-expansion-panel>
            </mat-nav-list>
        </as-split-area>

        <as-split-area [size]="contentWidth" flexOrder="1">
            <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" class="loader-margin"></mat-spinner>
            <div fxLayout="column" fxFill>
                <div id="mainContent" [hidden]="showLoader" fxFlex="grow">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </as-split-area>
    </as-split>
</div>