<section *ngIf="user != null" class="page-content">
    <form (ngSubmit)="saveInstruction()" [formGroup]="form">
        <mat-card>
            <mat-card-title>Instruction Details</mat-card-title>
            <mat-card-content>
                <div>
                    <material-form-input tabindex="1" [validationErrors]="validationErrors" [name]="'description'" fxFlex="">
                        <mat-form-field>
                            <textarea matInput placeholder="Instruction Description" formControlName="description"></textarea>
                        </mat-form-field>
                    </material-form-input>
                </div>
                <div class="margin-left">
                    <mat-card-actions class="actions-container">
                        <button mat-button type="submit" mat-raised-button color="primary" tabindex="2">Save</button>
                        <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="instruction-spinner-margin" [diameter]="30"></mat-spinner>
                    </mat-card-actions>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</section>