import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientPortalsInvitationDataSource } from '../../../types/patient-portals-invitation-datasource';
import { PortalInvitation, ServiceResponse, UnitServiceResponse } from '@dignity-health/ciam-auth';
import { FormControl, Validators } from '@angular/forms';
import { UiService } from '../../../services/ui/ui.service';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { REQUIRED_FIELD_ERROR_MESSAGE, INVALID_EMAIL_ERROR_MESSAGE } from '../../../types/constants';

@Component({
  selector: 'resend-modal',
  templateUrl: './resend-modal.component.html',
  styleUrls: ['./resend-modal.component.scss']
})

export class ResendModalComponent implements OnInit {
  email = new FormControl(this.data.recipientEmail, [Validators.required, Validators.email]);
  isTier1Group: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private uiService: UiService,
    public dialogRef: MatDialogRef<ResendModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PortalInvitation,
    public portalsDataSource: PatientPortalsInvitationDataSource,
    private oktaGroupService: OktaSupportGroupsService
  ) {
    this.isTier1Group = this.oktaGroupService.isTier1SupportGroup;
  }

  getErrorMessage() {
    return this.email.hasError('required') ? REQUIRED_FIELD_ERROR_MESSAGE :
      this.email.hasError('email') ? INVALID_EMAIL_ERROR_MESSAGE : '';
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async reSendInvitation() {
    // For tier 1 users, override the resend E-mail address with a default
    if (this.isTier1Group) {
      this.email.setValue(this.data.recipientEmail);
    }

    const invitation = await this.portalsDataSource.resendInvitation(this.data.invitationId, this.email.value) as ServiceResponse<UnitServiceResponse>;

    if (invitation.errors.length) {
      return this.uiService.showErrors(invitation.errors);
    }

    this.data.recipientEmail = this.email.value;
    this.dialogRef.close();
    this.openSnackBar(`Invitation is Successfully resent to ${this.data.recipientEmail}`, 'Success');
  }
}
