import { Component, OnInit } from '@angular/core';
import { COMMA, SPACE, TAB, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IAppState } from '../../../types/state';
import { GraphQLQuery } from '../../../types/graphql';
import { UiService } from '../../../services/ui/ui.service';
import { ValidationErrors } from '../../../types/forms';
import { CiamUser, CiamAuth, WhitelistDomainRequest } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

const DOMAINLIST_PATTERN = /^[\w-.]+\.[A-Za-z]{2,4}$/;
const GET_WHITELISTED_DOMAINS_QUERY = `query{
  whiteListDomains{
    whitelistDomainId
    whitelistDomainName
    isActive
  }
}`;

@Component({
  selector: 'app-domain-whitelisting',
  templateUrl: './domain-whitelisting.component.html',
  styleUrls: ['./domain-whitelisting.component.scss']
})

export class DomainWhitelistingComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  form: FormGroup;
  validationErrors: ValidationErrors = {};
  domainList = new FormControl('', [Validators.required]);

  isLoading = false;
  whitelistedDomains: string[] = [];
  whiteListedDomainsQuery: GraphQLQuery;
  whitelistedDomainResult: WhitelistDomainRequest;

  removable = true;
  addChipOnBlur = true;
  readonly separatorKeysCodes: number[] = [COMMA, SPACE, TAB, ENTER];

  constructor(
    private fb: FormBuilder,
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private store: Store<IAppState>) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.form = this.fb.group({
      'domainList': this.domainList
    });

    this.getWhitelistedDomains();
  }

  async getWhitelistedDomains() {
    this.isLoading = true;
    this.whiteListedDomainsQuery = {
      query: GET_WHITELISTED_DOMAINS_QUERY,
      variables: {}
    };

    const result = await this.ciamAuth.httpApi.apiInfoQueryPost(this.whiteListedDomainsQuery, false);
    if (result && result.errors && result.errors.length) {
      this.isLoading = false;
      this.uiService.showErrors(result.errors);
      return;
    }

    this.whitelistedDomainResult = result.data && result.data.whiteListDomains;
    if (this.whitelistedDomainResult) {
      this.whitelistedDomains = this.whitelistedDomainResult.whitelistDomainName.split(', ');
      this.updateFormValue();
    }

    this.isLoading = false;
  }

  async saveWhitelistedDomains() {
    this.isLoading = true;
    if (!this.form.valid || this.whitelistedDomainResult.whitelistDomainName == this.whitelistedDomains.join(", ")) {
      this.isLoading = false;
      this.updateFormValue();
      return;
    }

    this.whitelistedDomainResult.whitelistDomainName = this.whitelistedDomains.join(", ");
    const result = await this.ciamAuth.httpApi.apiAdminSitesettingsWhitelistDomainPatch(this.whitelistedDomainResult);
    if (result && result.errors && result.errors.length) {
      this.isLoading = false;
      this.uiService.showErrors(result.errors);
      return;
    }

    this.isLoading = false;
    this.uiService.openSnackbar('Domain List successfully updated', 'Success');
  }

  addChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!(value || '').trim()) {
      return;
    }
    const domain = value.trim();
    if (!DOMAINLIST_PATTERN.test(domain)) {
      this.domainList.setErrors({ 'pattern': true });
      return;
    }

    const index = this.whitelistedDomains.indexOf(domain);
    if (index >= 0) {
      this.domainList.setErrors({ 'duplicate': true });
      return;
    }

    this.whitelistedDomains.push(domain);
    this.updateFormValue();

    if (input) {
      input.value = '';
    }
  }

  removeChip(domain: string): void {
    const index = this.whitelistedDomains.indexOf(domain.trim());
    if (index >= 0) {
      this.whitelistedDomains.splice(index, 1);
      this.updateFormValue();
    }
  }

  pasteDomain(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n/)
      .forEach(value => {
        if (value.trim()) {
          const domain = value.trim();
          const index = this.whitelistedDomains.indexOf(domain);
          if (index >= 0) {
            this.domainList.setErrors({ 'duplicate': true });
            return;
          }

          if (!DOMAINLIST_PATTERN.test(domain)) {
            this.domainList.setErrors({ 'pattern': true });
            return;
          }

          this.whitelistedDomains.push(domain);
          this.updateFormValue();
        }
      });
  }

  updateFormValue() {
    this.form.setValue({
      domainList: this.whitelistedDomains.join(", ")
    });
  }
}
