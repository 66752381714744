export const ContentWidth: number = 83;
export const NavWidth: number = 17;
export const GutterSize: number = 3;
export const GutterDefaultSize: number = 1;
// Note: We cannot use new Date(0) here as that defaults to Jan 1st 1970, and we have to support birth dates prior to that.
export const MIN_DATE = new Date(1900, 1, 1);

export const SUPER_ADMIN_GROUP_NAME = 'My Help - Super Admin';
export const TIER_1_SUPPORT_GROUP_NAME = 'My Help - Tier 1 Support';
export const TIER_2_SUPPORT_GROUP_NAME = 'My Help - Tier 2 Support';
export const TIER_3_SUPPORT_GROUP_NAME = 'My Help - Tier 3 Support';

export const LOGIN: string = 'login';
export const FACILITIES_LIST: string = 'facilities';
export const USERS: string = 'users';
export const USER_DETAILS: string = 'users/view';
export const CERNER_INVITATIONS: string = 'cerner-invitations';

export const MAX_DATE_ERROR_MESSAGE = 'Please do not enter date in future.';
export const MAX_DAYS_RANGE_ERROR_MESSAGE = 'Please select a period less than 91 days.';
export const INVALID_DATE = 'Invalid date format.';
//one day = 1000 * 60 * 60 * 24 milliseconds.
export const PER_DAY_MILLISECONDS = 1000 * 60 * 60 * 24;
export const DOMAIN_VALUE_REQUIRED_ERROR = 'Please select a domain to associate.';
export const ADD_CLICK_REQUIRED_ERROR = ' will not be added. Click on + icon to add.';
export const REQUIRED_FIELD_ERROR_MESSAGE = 'You must enter a value';
export const INVALID_EMAIL_ERROR_MESSAGE = 'Not a valid email';
export const INVALID_USERNAME_ERROR_MESSAGE = 'Not a valid username';
export const RESET_PASSWORD_ERROR_MESSAGE = 'Unable to reset user password. Please try again later!';
export const RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_MESSAGE = 'This feature cannot be used if the user is in Recovery mode. Try using "\Reset without Security"\.';
export const ZENDESK_LINK  = 'https://officeofdigital.zendesk.com/hc/en-us/requests/new';
export const TEMP_PASSWORD_ERROR_MESSAGE = 'Unable to generate temporary password. Please try again later!';
export const UNPROCESSABLE_ENTITY_CODE = '422';
export const UNPROCESSABLE_ENTITY_MESSAGE = 'Too many records match your search criteria. Please refine your search.';

export const RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_CODE = 'E0000017';

export const ValidGroups:string[] = [
  SUPER_ADMIN_GROUP_NAME,
  TIER_1_SUPPORT_GROUP_NAME,
  TIER_2_SUPPORT_GROUP_NAME,
  TIER_3_SUPPORT_GROUP_NAME
];

export const MIGRATION = 'migration';
export const COMPLETED = 'completed';
