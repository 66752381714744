<div class="example-container">
  <h1 mat-dialog-title>Resend Invitation</h1>
  <mat-form-field>
    <input matInput placeholder="Enter your email" [readonly]="isTier1Group" [formControl]="email" cdkFocusInitial required>
    <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
  <div mat-dialog-actions>
    <button mat-button (click)="cancel()" color="accent">Cancel</button>
    <button mat-button color="primary" (click)="!email.invalid && reSendInvitation()">Resend</button>
  </div>
</div>
