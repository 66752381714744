import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ActionConfirmationComponent } from './action-confirmation/action-confirmation.component';
import { ConfirmationModalComponent } from './action-confirmation/confirmation-modal/confirmation-modal.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { AutoCloseDialogComponent } from './autoclose-dialog/autoclose-dialog.component';
@NgModule({
  imports: [MatDialogModule, MatIconModule, MatButtonModule, NgxDaterangepickerMd, BrowserModule],
  entryComponents: [ConfirmationModalComponent, WarningDialogComponent, ActionConfirmationComponent, DateRangePickerComponent,
    AutoCloseDialogComponent],
  exports: [MatDialogModule, MatIconModule, MatButtonModule, ActionConfirmationComponent, ConfirmationModalComponent,
    DateRangePickerComponent, BrowserModule, AutoCloseDialogComponent],
  declarations: [ActionConfirmationComponent, ConfirmationModalComponent, WarningDialogComponent, DateRangePickerComponent,
    AutoCloseDialogComponent]
})
export class AppSharedModule { }
