import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { UiService } from '../services/ui/ui.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public router: Router, public uiService: UiService, private oidcSecurityService: OidcSecurityService) { }

    async canActivate(): Promise<boolean> {
        try {
            this.oidcSecurityService.checkAuth().subscribe((isSignedIn) => {
                if (!isSignedIn) {
                    return this.blockActivationAndNavigateTo(this.router, 'login');
                }
            });

            if (environment.featureFlags.general.allowAllUsers) {
                return true;
            }

            return true;

        } catch (e) {
            this.uiService.openSnackbar('Something went wrong logging in!');

            return this.blockActivationAndNavigateTo(this.router, 'login');
        }
    }

    private blockActivationAndNavigateTo(router: Router, url: string): boolean {
        this.router.navigate([url]);

        return false;
    }
}
