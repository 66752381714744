<div class="page-content">
  <div class="users-grid-container">
    <div class="users-grid-header">
      <form (ngSubmit)="getInvitations()" [formGroup]="form" autocomplete="off">
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="0.25em">
          <mat-form-field  fxFlex="11%">
            <input matInput type="text" placeholder="Patient First Name" formControlName="patientFirstName" tabindex="1" />
          </mat-form-field>
          <mat-form-field  fxFlex="11%">
            <input matInput type="text" placeholder="Patient Last Name" formControlName="patientLastName" tabindex="2" />
          </mat-form-field>
          <mat-form-field  fxFlex="22%">
            <input matInput type="text" placeholder="Email" formControlName="recipientEmail" tabindex="3" />
          </mat-form-field>
          <mat-form-field fxFlex="15%">
            <input matInput type="text" #createdDateRange [(ngModel)]="createdDate" placeholder="Created Date" formControlName="createdDateRange" tabindex="4" 
                   (input)="manualDateChange($event.target.value)" />
            <mat-error *ngIf="form.controls['createdDateRange'].hasError('dateformat')">{{invalidDateMessage}}</mat-error>
            <mat-error *ngIf="form.controls['createdDateRange'].hasError('valid')">{{maxDaysErrorMessage}}</mat-error>
            <date-range-picker (dateRangeChange)="dateRangeChange($event)" (dateRangeClear)="dateRangeClear($event)"></date-range-picker>
          </mat-form-field>
          <mat-form-field fxFlex="13%">
            <input matInput [matDatepicker]="dateOfBirth" placeholder="Patient Date Of Birth" formControlName="patientDateOfBirth" tabindex="5" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
            <mat-error *ngIf="form.controls['patientDateOfBirth'].hasError('matDatepickerMin')">Please enter DOB in format MM/DD/YYYY.</mat-error>
            <mat-error *ngIf="form.controls['patientDateOfBirth'].hasError('matDatepickerMax')">{{maxDateErrorMessage}}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="11%">
            <input matInput type="text" placeholder="Domain" formControlName="domain" tabindex="6" />
          </mat-form-field>
          <mat-form-field fxFlex="10%">
            <mat-select placeholder="Invitation Status" formControlName="status" tabindex="7">
              <mat-option *ngFor="let status of statusTypes" [value]="status.value">
                {{ status.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button type="submit" matTooltip="Search Invitations!" fxFlex="10em" tabindex="8">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
    <progress-bar [isLoading]="isLoading"></progress-bar>
  <div *ngIf="rowCount > 0">

    <mat-table #table [dataSource]="dataSource">
      <!-- patientFirstName Column -->
      <ng-container matColumnDef="patientFirstName">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef>Patient First Name </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row"> {{row.patientFirstName}} </mat-cell>
      </ng-container>
      <!-- patientLastName Column -->
      <ng-container matColumnDef="patientLastName">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef> Patient Last Name </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row"> {{row.patientLastName}} </mat-cell>
      </ng-container>
      <!-- patientDateOfBirth Column -->
      <ng-container matColumnDef="patientDateOfBirth">
        <mat-header-cell fxFlex="8%" *matHeaderCellDef> Patient DOB</mat-header-cell>
        <mat-cell fxFlex="8%" *matCellDef="let row"> {{row.patientDateOfBirth | utcDate | date}} </mat-cell>
      </ng-container>
      <!-- InvitationSource Column -->
      <ng-container matColumnDef="invitationSource">
        <mat-header-cell fxFlex="6%" *matHeaderCellDef>Invitation Source</mat-header-cell>
        <mat-cell fxFlex="6%" *matCellDef="let row"> {{row.invitationSource}} </mat-cell>
      </ng-container>
      <!-- domainName Column -->
      <ng-container matColumnDef="domainName">
        <mat-header-cell fxFlex="6%" *matHeaderCellDef> Domain </mat-header-cell>
        <mat-cell fxFlex="6%" *matCellDef="let row"> {{row.domainName}} </mat-cell>
      </ng-container>
      <!-- verificationCode Column -->
      <ng-container matColumnDef="verificationCode">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef> Verification Code </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row"> {{row.verificationCode}} </mat-cell>
      </ng-container>
      <!-- patientRecipientEmail Column -->
      <ng-container matColumnDef="patientRecipientEmail">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef> Email Address </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row"> {{row.recipientEmail}} </mat-cell>
      </ng-container>
      <!-- status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell fxFlex="6%" *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell fxFlex="6%" *matCellDef="let row" [style.color]="statusColor(row.status)"> {{row.status}} </mat-cell>
      </ng-container>
      <!-- createdDate Column -->
      <ng-container matColumnDef="createdDate">
        <mat-header-cell fxFlex="8%" *matHeaderCellDef> Created Date</mat-header-cell>
        <mat-cell fxFlex="8%" *matCellDef="let row"> {{row.createdDate | date}} </mat-cell>
      </ng-container>
      <!-- lastSent Column -->
      <ng-container matColumnDef="dateSent">
        <mat-header-cell fxFlex="8%" *matHeaderCellDef> Last Sent </mat-header-cell>
        <mat-cell fxFlex="8%" *matCellDef="let row"> {{row.dateSent | date}} </mat-cell>
      </ng-container>
      <!-- claimedDate Column -->
      <ng-container matColumnDef="claimedDate">
        <mat-header-cell fxFlex="8%" *matHeaderCellDef> Claimed Date</mat-header-cell>
        <mat-cell fxFlex="8%" *matCellDef="let row"> {{row.dateClaimed | date}} </mat-cell>
      </ng-container>
      <!-- expiredDate Column -->
      <ng-container matColumnDef="expiredDate">
        <mat-header-cell fxFlex="8%" *matHeaderCellDef> Expired Date</mat-header-cell>
        <mat-cell fxFlex="8%" *matCellDef="let row"> {{calculateExpirationDate(row) | date}} </mat-cell>
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="6%" *matHeaderCellDef> Actions</mat-header-cell>
        <mat-cell fxFlex="6%" *matCellDef="let row">
          <i class="material-icons icon-hover" style="margin-right: 10%;" (click)="openExpireModal(row)" matTooltip="Click here to expire the Invitation.">query_builder</i>
          <button class="button-without-border" [disabled]="row.status == 'confirmed'" matTooltip="Click here to resend the Invitation." (click)="openResendModal(row)"><i class="material-icons">send</i></button>
        </mat-cell>
      </ng-container>
      <!-- Symbol Column -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [length]="rowCount"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="getInvitations($event)">
    </mat-paginator>

  </div>
  <div *ngIf="showNoResultMessage" class="left-margin">
    No invitations found. Please try searching again.
  </div>
</div>
