<h2 mat-dialog-title>Reset User Password</h2>

<mat-dialog-content>
    <div class="outerDiv">
        <div class="contentDiv">
            A password reset link is sent to this user's primary email address; the password is not automatically
            reset. The password reset link will expire 1 hour after it is sent. This method requires the user knows
            their Security Question.
        </div>
        <div class="actionDiv">
            <action-confirmation class="float-right" [actionConfirmation]="withSecurityQuestionActionConfirmationData"
                (userResponse)="resetUserPasswordResponse($event)">
            </action-confirmation>
            <mat-spinner *ngIf="showWithSecuritySpinner" class="float-left" [diameter]="30"></mat-spinner>
        </div>
    </div>
    <app-temporary-user-password-dialog></app-temporary-user-password-dialog>
</mat-dialog-content>