<ng-template mat-tab-label>
  <mat-icon>person_pin</mat-icon> Groups
</ng-template>

<mat-card *ngIf="selectedUser">
  <div fxLayout="row">
    <div fxFlex="50%">
      <div *ngIf="isMyHomeUser">
        <mat-card-title>Okta Groups</mat-card-title>
        <mat-card-content>
          <mat-spinner *ngIf="showOktaGroupsLoading" [diameter]="30"></mat-spinner>
          <ul *ngIf="!showOktaGroupsLoading">
            <li *ngFor="let group of selectedUser.oktaGroups">
              {{group.oktaGroupName}}
            </li>
          </ul>
        </mat-card-content>
      </div>

      <mat-card-title>Ciam Portals</mat-card-title>
      <mat-card-content>
        <mat-spinner *ngIf="showPortalLoading" [diameter]="30"></mat-spinner>
        <span *ngIf="showNoCiamPortalsMessage">No CIAM portal(s) for {{selectedUser.firstName}}
          {{selectedUser.lastName}}</span>
        <ul>
          <li *ngFor="let ciamPortal of ciamPortals">
            {{ciamPortal.portal.name}}
          </li>
        </ul>
      </mat-card-content>

      <mat-card-title>Patient Portals</mat-card-title>
      <mat-card-content>
        <mat-spinner *ngIf="showPatientPortalLoading || isRevokeInProgress" [diameter]="30"></mat-spinner>
        <span *ngIf="showNoPatientPortalsMessage">No Patient portal(s) for {{selectedUser.firstName}}
          {{selectedUser.lastName}}</span>
        <div *ngIf="!showPatientPortalLoading && !isRevokeInProgress && oktaGroupService.isSuperAdmin" >
          <div class="fieldset" *ngFor="let patientPortal of portalInformation; let i = index">
            <div class="legend">{{patientPortal.portalName}}</div>
            <div class="button-alignment">
              <div *ngIf="patientPortal.message !== ''" class="status-details">{{ patientPortal.message }}</div>
              <button *ngIf="hasResetPermission" (click)="openResetOptions(patientPortal.portalName);" class="warn-btn ponter-cursor">Reset</button>
              &nbsp;&nbsp;
              <action-confirmation *ngIf="hasRevokePermission && patientPortal.isRevokeEligible"
                                  [actionConfirmation]="actionConfirmationData"
                                  (userResponse)="revokePortalAccess($event, patientPortal.portalName, i)">
              </action-confirmation>
            </div>
          </div>
        </div>
        <ul *ngIf="!showPatientPortalLoading && (oktaGroupService.isTier1SupportGroup || oktaGroupService.isTier2SupportGroup)">
          <li *ngFor="let patientPortal of portalInformation; let i = index">
            {{ patientPortal.portalName }}
            <span class="status-details"> ({{ patientPortal.hasErrorEnrollment ? patientPortal.message : '' }})</span>
          </li>
        </ul>
      </mat-card-content>
    </div>
    <div fxFlex="50%" *ngIf="isMyHomeUser">      
        
      <mat-card-title>Edw Domains</mat-card-title>
      <mat-card-content>
        <mat-spinner *ngIf="showPortalLoading" [diameter]="30"></mat-spinner>
        <span *ngIf="showNoEdwPortalsMessage">No Edw Domain(s) for {{selectedUser.firstName}} {{selectedUser.lastName}}</span>
        <ul>
          <li *ngFor="let domain of edwDomains">
            {{domain}}
          </li>
        </ul>
      </mat-card-content>

      <mat-card-title>Edw Facilities </mat-card-title>
      <mat-card-content>
        <mat-spinner *ngIf="showPortalLoading" [diameter]="30"></mat-spinner>
        <span *ngIf="showNoEdwPortalsMessage">
          No Edw Facilities(s) for {{selectedUser.firstName}}
          {{selectedUser.lastName}}
        </span>
        <ul>
          <li *ngFor="let edwPortal of edwPortals">
            {{edwPortal.facility?.edwFacilityName}}
          </li>
        </ul>
      </mat-card-content>
        
      </div>
    </div>
</mat-card>
