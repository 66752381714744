import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FacilityDomainInfo, FacilityDomain } from '@dignity-health/ciam-auth';
import { DOMAIN_VALUE_REQUIRED_ERROR, ADD_CLICK_REQUIRED_ERROR } from 'app/types/constants';

@Component({
  selector: 'facility-domain-association',
  templateUrl: './facility-domain-association.component.html',
  styleUrls: ['./facility-domain-association.component.scss']
})
export class FacilityDomainAssociationComponent implements OnInit {
  @Input()
  domains: FacilityDomainInfo[];

  @Input()
  domainAssociation: FacilityDomain;

  @Input()
  pending = false;

  @Output()
  remove = new EventEmitter<FacilityDomainInfo>();

  @Output()
  add = new EventEmitter<FacilityDomainInfo>(); 

  constructor() { }

  error: boolean = false;
  saveError: boolean = false;
  requiredError = DOMAIN_VALUE_REQUIRED_ERROR;
  requiredAddClickError = ADD_CLICK_REQUIRED_ERROR;

  ngOnInit() {
    if (!this.domainAssociation || this.pending) {
      this.domainAssociation = this.makeNewDomainAssocation();
    }
  }

  onRemove() {
    this.remove.emit(this.domainAssociation);
  }

  checkFields() {
    this.error = false;
    this.saveError = false;
    if (this.domainAssociation.domainName) {
      this.requiredAddClickError = `${ this.domainAssociation.domainName } ${ this.requiredAddClickError }`;//this.domainAssociation.domainName + this.requiredAddClickError;
      this.saveError = true;
      return false;
    }
    return true;
  }

  onAdd() {
    this.saveError = false;
    this.error = false;
    if (!this.domainAssociation.domainName) {
      this.error = true;
      return false;
    }

    this.add.emit(this.domainAssociation);

    this.domainAssociation = this.makeNewDomainAssocation();
  }

  private makeNewDomainAssocation(): FacilityDomain {
    return {
      isActive: true
    };
  }
}
