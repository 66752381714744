import { Component, Inject, ViewChild } from '@angular/core';
import { UpdateFacilityRequest, Facility } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../types';
import { UiService } from '../../../services/ui/ui.service';
import { FacilitiesService } from '../../../services/facilities/facilities-service';

import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilitiesCreateUpdateComponent } from 'app/components/facility/facilities-create-update/facilities-create-update.component';

@Component({
  selector: 'facilities-quick-edit-modal',
  templateUrl: './facilities-quick-edit-modal.component.html',
  styleUrls: ['./facilities-quick-edit-modal.component.scss']
})
export class FacilitiesQuickEditModalComponent {
  updateRequest: UpdateFacilityRequest

  @ViewChild("facilitiesCreateUpdateComponent") child: FacilitiesCreateUpdateComponent;

  constructor(
    private store: Store<IAppState>,
    private facilitiesService: FacilitiesService,
    private uiService: UiService,
    private dialogRef: MatDialogRef<FacilitiesQuickEditModalComponent, FacilitiesQuickEditModalComponentData>,
    @Inject(MAT_DIALOG_DATA) private data: FacilitiesQuickEditModalComponentData) {

    const facility = data.facility;

    // Clone the facility to use to populate the update request
    this.updateRequest = {
      friendlyFacilityName: facility.friendlyFacilityName,
      isActive: facility.isActive,
      edwFacilityName: facility.edwFacilityName,
      barGroupId: (facility.barGroup || {}).barGroupId,
      latitude: facility.latitude,
      longitude: facility.longitude,
      registrationSystem: facility.registrationSystem,
      schedulingLocationId: facility.schedulingLocationId,
      webAddress: facility.webAddress,
      facilityDomains: !facility.domains ? null : _.cloneDeep(facility.domains).map(fd => {
        return {
          domainName: fd.name,
          isActive: fd.isActive,
          beginDate: fd.beginDate,
          endDate: fd.endDate
        };
      }),
      alternateFacilityNames: !facility.alternateNames ? null : _.clone(facility.alternateNames).map(a => a.alternateFacilityName),
      address: {
        line1: facility.addressLine1,
        line2: facility.addressLine2,
        zipCode: facility.zipCode,
        stateOrProvince: facility.stateOrProvince,
        city: facility.city,
        countryCode: facility.countryCode
      }
    }
  }

  async onClickSave() {
    try {
      if (!this.child.checkDomainFields()) {
        return;
      }

      const result = await this.facilitiesService.updateFacility(this.data.facility.facilityId, this.updateRequest);
      if (!result.isValid) {
        return this.uiService.showErrors(result.errors);
      }

      this.uiService.openSnackbar("Successfully saved facility!", "Awesome!");
      this.dialogRef.close({
        facility: result.result,
        didUpdate: true
      });
    } catch (e) {
      this.uiService.showException(e);
    }
  }

  onNoClick() {
    this.dialogRef.close({});
  }
}

export interface FacilitiesQuickEditModalComponentData {
  facility?: Facility;
  didUpdate?: boolean;
}
