import { ServiceResponseError } from '@dignity-health/ciam-auth';
import { Component, Inject, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { DatePipe } from '@angular/common';

interface ErrorDialogData {
  errors: ServiceResponseError[];
  showErrorCode: boolean;
}

@Injectable()
export class UiService {
  constructor(private dialog: MatDialog, private datePipe: DatePipe, private snackbar: MatSnackBar) { }

  openSnackbar(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    config = {
      duration: 5000,
      ...config
    };

    return this.snackbar.open(message, action, config);
  }

  showException(exception: any) {
    this.snackbar.open(`Something bad happened!\n${exception}`, 'Whoops!', {
      duration: 0
    });

    this.showErrors([
      {
        code: "unknown",
        message: `Something really bad happened. Please let your administrator know!`
      },
      {
        code: "stack-trace",
        message: exception
      }
    ], true);
  }

  showErrors(errors: ServiceResponseError[], showErrorCode: boolean = false) {
    const data: ErrorDialogData = {
      errors: errors,
      showErrorCode: showErrorCode
    };

    try {
      console.log('error details:', errors);
    } catch (e) {
      // suppress the exception in case of console not be be available in the execution context
    }

    this.dialog.open(UiServiceErrorsDialogComponent, {
      data: data
    });
  }

  formatDate(date?: Date): any {
    return date ? this.datePipe.transform(date, 'MM/dd/yyyy') : null;
  }

  turnLocalDateIntoUtcDate(localDate: Date): Date {
    if (!localDate) {
      return null;
    }

    return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
  }
}

@Component({
  selector: 'ui-service-errors-dialog',
  template: `
    <section [ngSwitch]="hasErrors()">
      <section *ngSwitchCase="true">
        <h3>Uh-oh! Something went wrong:</h3>
        <ul *ngFor="let error of errors">
          <li><span *ngIf="showErrorCode">{{error.code}}: </span><span>{{error.message}}</span></li>
        </ul>
      </section>

      <section *ngSwitchCase="false">
        <h3>Huh. Someone opened an error dialog without any errors...</h3>
      </section>
    </section>
  `
})
export class UiServiceErrorsDialogComponent {
  errors: ServiceResponseError[];
  showErrorCode: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData) {
    this.errors = data.errors;
    this.showErrorCode = data.showErrorCode;
  }

  hasErrors(): boolean {
    return this.errors && this.errors.length != 0;
  }
}
