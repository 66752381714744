<div class="search-component" [ngClass]="{'on-top': this.hasResults}" fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="patientEnrollmentsSearchForm">
        <div fxFlex="100%">
            <div fxLayout="row" fxLayoutGap="10px">

                <mat-form-field fxFlex="20%">
                    <input matInput type="text" title="Username" placeholder="Username" formControlName="userName" />
                </mat-form-field>

                <mat-form-field fxFlex="10%">
                    <input matInput type="text" title="Portal Name" placeholder="Portal Name" formControlName="portalName" />
                </mat-form-field>

                <mat-form-field fxFlex="10%">
                    <input matInput [matDatepicker]="fromDatePicker" placeholder="From Date" name="fromDate" [max]="maxDate"
                        formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex="10%">
                    <input matInput [matDatepicker]="toDatePicker" placeholder="To Date" name="toDate" [max]="maxDate"
                        [min]="fromDate" formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex="10%">
                    <mat-select placeholder="States" formControlName="states">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let state of patientEnrollmentStatuses" [value]="state.value">
                            {{ state.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="15%">
                    <mat-select placeholder="Enrollment Types" formControlName="type">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let type of patientEnrollmentTypes" [value]="type.value">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div fx-flex="25%">
                    <button class="search-button" mat-raised-button [color]="'primary'" [disabled]="isLoading" (click)="modifySearch()">
                        <mat-icon>search</mat-icon> Search
                    </button>
                    <button class="search-button" mat-raised-button [color]="'accent'" [hidden]="!this.hasResults"
                        (click)="resetPatientEnrollmentSearchParams()">
                        <mat-icon>clear_all</mat-icon> Clear
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>