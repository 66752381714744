import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiService } from '../../../services/ui/ui.service';
import { REQUIRED_FIELD_ERROR_MESSAGE, INVALID_USERNAME_ERROR_MESSAGE } from '../../../types/constants';

@Component({
  selector: 'update-username',
  templateUrl: './update-username.component.html',
  styleUrls: ['./update-username.component.scss']
})
export class UpdateUsernameComponent implements OnInit {
  newUsername = new FormControl(this.username, [Validators.required, Validators.email]);
  showLoader: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public username: any,
    public snackBar: MatSnackBar,
    private uiService: UiService,
    private ciamAuth: CiamAuth,
    private router: Router,
    public dialogRef: MatDialogRef<UpdateUsernameComponent>
  ) { }

  getErrorMessage() {
    return this.newUsername.hasError('required') ? REQUIRED_FIELD_ERROR_MESSAGE :
      this.newUsername.hasError('email') ? INVALID_USERNAME_ERROR_MESSAGE : '';
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async updateUsername() {
    this.showLoader = true;
    this.ciamAuth.httpApi.apiIntegrationsUsersUpdateUsernameByUsernamePatch
      (this.username, this.newUsername.value).then(response => {
        this.showLoader = false;
        if (response && response.errors && response.errors.length) {
          return this.uiService.showErrors(response.errors);
        }

        this.openSnackBar(`Username ${this.username} is successfully updated to ${this.newUsername.value}`, 'Success');
        this.dialogRef.close();
        this.router.navigate(['/users/view', { user: this.newUsername.value }]);
      })
      .catch(errors => {
        this.showLoader = false;
        return this.uiService.showErrors(errors);
      });;
  }
}
