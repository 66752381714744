import { UserSearchRequest } from '../../types/userSearchRequest';
import { PatientSearchRequest } from '../../types/patientSearchRequest';
import { IUserSearch } from 'app/types/state';
import { UPDATE_USER_SEARCH, UPDATE_PATIENT_SEARCH, UPDATE_CERNER_SEARCH } from '../actions/userSearch';

export function userSearch(state: IUserSearch, { type, payload }): IUserSearch {
  switch (type) {
    case UPDATE_USER_SEARCH:
      return {
        ...state,
        myHomeSearch: payload
      };

    case UPDATE_PATIENT_SEARCH:
      return {
        ...state,
        myCareSearch: payload
      };

    case UPDATE_CERNER_SEARCH:
      return {
        ...state,
        cernerSearch: payload
      };

    default:
      return state;
  }
}
