import { UpdateUser } from '../../../store/actions/user';
import { CiamUser, CiamAuth, ServiceResponse, InstructionRequest } from '@dignity-health/ciam-auth'
import { Component, OnInit } from '@angular/core';
import { Store, createAction, props } from '@ngrx/store';
import { IAppState } from '../../../types/state';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getValidationErrors } from '../../../types/helpers';
import { ValidationErrors } from 'app/types/forms';
import { UiService } from '../../../services/ui/ui.service';
import { ValidGroups } from '../../../types/constants';
import { GraphQLQuery } from '../../../types/graphql';
import { ZENDESK_LINK } from '../../../types/constants';
import { InstructionService } from 'app/services/instructions/instructions-service';
import { UpdatePatientSearch, UpdateCernerSearch, UpdateUserSearch } from 'app/store/actions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

const GET_INSTRUCTION_QUERY = `
    query getInstruction{
      myHelpInstructions{
        instructionId
        title
        description
        isActive
      }}`;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  resolvingUser: boolean;
  user: CiamUser;
  user$: Observable<CiamUser>;
  showSpinner = false;
  form: FormGroup;
  getInstructionQuery: GraphQLQuery;
  instruction: InstructionRequest;
  description = '';

  validationErrors: ValidationErrors = {};
  dispatchUser = createAction(
    'Update user in store',
    props<{ user: CiamUser }>()
  );
  constructor(private fb: FormBuilder, private ciamAuth: CiamAuth, private uiService: UiService,
    private store: Store<IAppState>, private router: Router,
    private instructionService: InstructionService, private oidcSecurityService: OidcSecurityService) {
    this.user$ = this.store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);

    this.store.dispatch(new UpdateUserSearch(null));
    this.store.dispatch(new UpdatePatientSearch(null));
    this.store.dispatch(new UpdateCernerSearch(null));
  }

  private doUserResolvingAction<T>(action: () => Promise<T>): Promise<T> {
    this.resolvingUser = true;

    const promise = action();

    promise.catch(error => {
      this.uiService.openSnackbar(error.toString(), null, { duration: 2500 });

      this.resolvingUser = false;
    });

    promise.then(() => this.resolvingUser = false);

    return promise;
  }

  private updateUser(user: CiamUser) {
    this.user = user;
    this.store.dispatch(this.dispatchUser({ user: this.user }));
    //this.user$.dispatch(new UpdateUser(user));
  }

  async login(): Promise<void> {    
    localStorage.removeItem('searchType');
    this.oidcSecurityService.authorize();

  }

  async signOut(): Promise<void> {
    this.doUserResolvingAction(async () => {
      await this.ciamAuth.session.signOut();
      this.updateUser(null);
      this.router.navigate(['login']);
      this.store.dispatch(new UpdateUser(null));
    });
  }

  async ngOnInit() {  
    this.getInstruction();
  }


  async getInstruction() {
    this.instruction = await this.instructionService.getMyHelpInstructions();

    if (this.instruction) {
      this.description = this.instruction.description;
    }
  }

  private needAccess() {
    window.location.href = ZENDESK_LINK;
  }
}
