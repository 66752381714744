import { Component, OnInit, Input } from '@angular/core';
import { CiamAuth, ServiceResponse, UnitServiceResponse, UpdateUserProfileRequestGender, UpdateUserProfileRequest } from '@dignity-health/ciam-auth';
import { ValidationErrors } from 'app/types';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UiService } from '../../../services/ui/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtcDatePipe } from '../../../pipes/utc-date/utc-date.pipe';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { REQUIRED_FIELD_ERROR_MESSAGE, INVALID_EMAIL_ERROR_MESSAGE } from '../../../types/constants';
import { CiamPersonIdLevel } from '@dignity-health/ciam-auth';

@Component({
  selector: 'app-user-profile-overview',
  templateUrl: './user-profile-overview.component.html',
  styleUrls: ['./user-profile-overview.component.scss'],
  providers: [UtcDatePipe]
})

export class UserProfileOverviewComponent implements OnInit {

  @Input()
  selectedUser: any;
  @Input()
  isMyHomeUser: any;
  isLevel4User = false;
  genders = UpdateUserProfileRequestGender;
  form: FormGroup;
  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  gender = new FormControl('', [Validators.required]);
  showSpinner: boolean = false;
  canUpdateUserProfile: boolean;

  validationErrors: ValidationErrors = {};
  constructor(private fb: FormBuilder,
    private uiService: UiService,
    private snackBar: MatSnackBar,
    private ciamAuth: CiamAuth,
    private oktaGroupService: OktaSupportGroupsService,
    private utcDatePipe: UtcDatePipe
  ) {
    // Tier 1 Support Group doesn't have access to update the user's profile.
    this.canUpdateUserProfile = !this.oktaGroupService.isTier1SupportGroup;
  }

  ngOnInit() {
    this.initForm(this.selectedUser);
    if (this.isMyHomeUser !== undefined && !this.isMyHomeUser) {
      this.canUpdateUserProfile = false;
      this.form.disable();
      return;
    }
    const level4Group = this.selectedUser.oktaGroups.find(grp => {
      return grp.oktaGroupName.replace(' ', '').toLowerCase() === CiamPersonIdLevel.Level4.toString().toLowerCase();
    })
    if (level4Group) {
      this.isLevel4User = true;
    }
  }

  initForm(selectedUser: any) {
    if (!selectedUser) {
      return;
    }

    this.firstName.setValue(selectedUser.firstName);
    this.lastName.setValue(selectedUser.lastName);
    this.email.setValue(selectedUser.email);
    this.gender.setValue(selectedUser.gender);
    var selectedUserDateOfBirth = this.utcDatePipe.transform(selectedUser.dateOfBirth);

    this.form = this.fb.group({
      'firstName': this.firstName,
      'lastName': this.lastName,
      'email': this.email,
      'phoneNumber': new FormControl(selectedUser.phoneNumber),
      'dateOfBirth': new FormControl(selectedUserDateOfBirth),
      'addressLine1': new FormControl(selectedUser.address ? selectedUser.address.line1 : ''),
      'addressLine2': new FormControl(selectedUser.address ? selectedUser.address.line2 : ''),
      'city': new FormControl(selectedUser.address ? selectedUser.address.city : ''),
      'gender': this.gender,
      'zipCode': new FormControl(selectedUser.address ? selectedUser.address.zipCode : ''),
      'stateOrProvince': new FormControl(selectedUser.address ? selectedUser.address.stateOrProvince : '')
    });
  }

  async updateUserProfile() {
    if (!this.form.valid) {
      return;
    }
    this.showSpinner = true;
    const controls = this.form.controls;
    const userProfile = await this.ciamAuth.httpApi.apiUsersMeByPersonIdPatch(this.selectedUser.selfId,
      this.setUpdateUserProfileRequest(controls)) as ServiceResponse<UnitServiceResponse>;

    if (userProfile.errors.length) {
      return this.uiService.showErrors(userProfile.errors);
    }
    this.showSpinner = false;
    this.snackBar.open('User Profile Updated Successfully!', null, { duration: 2500 });
  }

  setUpdateUserProfileRequest(controls: any): UpdateUserProfileRequest {
    return {
      firstName: controls['firstName'].value,
      lastName: controls['lastName'].value,
      email: controls['email'].value,
      dateOfBirth: this.uiService.formatDate(controls['dateOfBirth'].value),
      gender: <UpdateUserProfileRequestGender>controls['gender'].value,
      phoneNumber: controls['phoneNumber'].value,
      address: {
        line1: controls['addressLine1'].value,
        line2: controls['addressLine2'].value,
        stateOrProvince: controls['stateOrProvince'].value,
        zipCode: controls['zipCode'].value,
        city: controls['city'].value
      }
    }
  }

  getErrorMessage() {
    return this.email.hasError('required') ? REQUIRED_FIELD_ERROR_MESSAGE :
      this.email.hasError('email') ? INVALID_EMAIL_ERROR_MESSAGE : '';
  }
}
