 <div class="region-container">
    <h1 mat-dialog-title>Region Details</h1>
    <mat-form-field>
      <input matInput placeholder="Enter Region Name" [formControl]="regionName" cdkFocusInitial required>
      <mat-error *ngIf="this.regionName.hasError('required')">Region Name is required</mat-error>
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-button matDialogClose color="accent">Cancel</button>
      <button mat-button color="primary" (click)="!regionName.invalid && saveRegion()">Save</button>
      <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="region-spinner-margin" [diameter]="30"></mat-spinner>
    </div>
  </div>
