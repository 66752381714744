import { Component, Input, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';

import { Router } from '@angular/router';
import { CiamUser, Patient } from '@dignity-health/ciam-auth';
import { PagedResult } from '@dignity-health/ciam-auth/dist/src/graphql';
import { IAppState } from 'app/types';
import { PatientModel, SortInfo } from 'app/types/models';
import { GraphQLQuery } from 'app/types/graphql';
import { SEARCH_TYPE } from 'app/shared/enums/enums';
import { UiService } from 'app/services/ui/ui.service';
import { UsersDataSource } from 'app/types/users-datasource';
import { PatientSearchRequest } from 'app/types/patientSearchRequest';
import { UpdatePatientSearch, UpdateCernerSearch } from 'app/store/actions';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { UNPROCESSABLE_ENTITY_CODE, UNPROCESSABLE_ENTITY_MESSAGE } from 'app/types/constants';
import { Observable } from 'rxjs';

const GET_CERNER_PATIENTS = `query getCernerPatients(
    $patientId: String
    , $firstName: String
    , $lastName: String
    , $mrn: String
    , $dateOfBirth: Date
  ){
  searchCernerPatients(searchRequest: {
      patientId: $patientId,
      firstName: $firstName,
      lastName: $lastName
      mrn: $mrn,
      dateOfBirth: $dateOfBirth      
  })
    {
      id,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      domainName,
      accountType
    }
  }`;

const GET_PATIENTS = `query getPatients(
  $firstName: String
  , $lastName: String
  , $mrn: String
  , $dateOfBirth: Date
  , $pageSize: Int
  , $currentPage: Int
  , $sortColumn: String
  , $sortOrder: String)
{
  searchPatients(
    searchRequest: {
      firstName: $firstName,
      lastName: $lastName
      mrn: $mrn,
      dateOfBirth: $dateOfBirth     
    },
    sortInfo: {     
      sortColumn: $sortColumn,
      sortOrder: $sortOrder
    },
    pageInfo: { 
      pageSize: $pageSize,
      currentPage: $currentPage
    }
  )  
  {
    currentPage
    pageSize
    firstRowOnPage      
    lastRowOnPage
    pageCount
    rowCount      
    results{        
      patientId
      firstName
      lastName
      dateOfBirth
      gender      
      userPatientRelationships { 
        relationshipType          
        patientId
        user {
          userId
          username          
          self {              
            firstName
            lastName
            dateOfBirth
            gender
            idLevel                      
          }            
        }
      }
      patientMrns{
        patientMrnId
        mrn
      }
    }
  }
}`;

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})

export class PatientsComponent implements OnChanges {

  user: CiamUser;
  user$: Observable<CiamUser>;
  @Input() searchType: string;
  @ViewChild(MatTable) table: MatTable<PatientModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchPatientsQuery: GraphQLQuery;
  patientSearchParams: PatientSearchRequest = <PatientSearchRequest>{
    patientId: '',
    mrn: '',
    lastName: '',
    firstName: '',
    dateOfBirth: null,
    isValidSearch: false
  };
  dataSource: MatTableDataSource<PatientModel>;

  rowCount = 0;
  pageSize = 10;
  currentPage = 1;
  isLoading = false;
  noResultsMessage: string;
  sortInfo: SortInfo = { sortColumn: '', sortOrder: 'asc' };
  myCareColumns = ['username', 'firstName', 'lastName', 'dateOfBirth', 'gender', 'accountType'];
  cernerColumns = ['username', 'firstName', 'lastName', 'dateOfBirth', 'gender', 'accountType', 'personID', 'domainName', 'facilitiesVisited'];

  constructor(
    private router: Router
    , public dialog: MatDialog
    , private uiService: UiService
    , private store: Store<IAppState>
    , private usersDataSource: UsersDataSource
    , private cd: ChangeDetectorRef
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => { this.user = user; });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchType = changes.searchType.currentValue;
    this.clear(null);
    this.patientSearchParams = {
      patientId: '', dateOfBirth: null, firstName: '', isValidSearch: false, mrn: '', lastName: ''
      , accountType: ''
    }
  }

  onPaginateChange(pageEvent: PageEvent) {
    this.pageSize = pageEvent ? pageEvent.pageSize : this.pageSize;
    this.currentPage = pageEvent ? pageEvent.pageIndex + 1 : 1;
    this.setPatientSearchParams(this.patientSearchParams);
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    if (this.searchType == SEARCH_TYPE.MY_CARE) {
      this.sortInfo = { sortColumn: sort.active, sortOrder: sort.direction };
      this.isLoading = true;
      this.getPatients(this.patientSearchParams);
    }
  }

  // Get Patients
  getPatients(patientSearchParams: PatientSearchRequest) {
    this.usersDataSource.searchPatients({
      query: GET_PATIENTS,
      variables: {
        'pageSize': this.pageSize,
        'currentPage': this.currentPage,
        'sortOrder': this.sortInfo.sortOrder,
        'sortColumn': this.sortInfo.sortColumn,
        'mrn': patientSearchParams.mrn,
        'lastName': patientSearchParams.lastName,
        'firstName': patientSearchParams.firstName,
        'dateOfBirth': this.uiService.turnLocalDateIntoUtcDate(patientSearchParams.dateOfBirth)
      }
    })
      .then(response => {
        this.isLoading = false;

        // Handle the errors elegantly.
        if (response && response.errors && response.errors.length) {
          return this.uiService.showErrors(response.errors);
        }

        if (response && response.data && response.data.searchPatients && response.data.searchPatients.results) {
          if (response.data.searchPatients.results.length == 0) {
            this.noResultsMessage = 'No results found';
            return;
          }

          let patients = this.toPatientModelArray(response.data.searchPatients.results);
          const pagedResult = <PagedResult<PatientModel>>(response.data.searchPatients);

          this.rowCount = pagedResult.rowCount;
          this.pageSize = pagedResult.pageSize;
          this.currentPage = pagedResult.currentPage;

          this.dataSource = new MatTableDataSource<PatientModel>(<PatientModel[]>(patients));
          this.noResultsMessage = '';

          return;
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  // Get Cerner Patients
  getCernerPatients(patientSearchParams: PatientSearchRequest) {
    this.usersDataSource.searchPatients({
      query: GET_CERNER_PATIENTS,
      variables: {
        'patientId': patientSearchParams.patientId,
        'mrn': patientSearchParams.mrn,
        'lastName': patientSearchParams.lastName,
        'firstName': patientSearchParams.firstName,
        'dateOfBirth': this.uiService.turnLocalDateIntoUtcDate(patientSearchParams.dateOfBirth)
      }
    })
      .then(response => {
        this.isLoading = false;
        if (response && response.errors && response.errors.length) {
          if (_.find(response.errors, { message: UNPROCESSABLE_ENTITY_CODE })) {
            this.noResultsMessage = UNPROCESSABLE_ENTITY_MESSAGE;
            return;
          }
          return this.uiService.showErrors(response.errors);
        }

        if (response && response.data && response.data.searchCernerPatients) {
          if (response.data.searchCernerPatients.length === 0) {
            this.noResultsMessage = 'No results found';
            return;
          }
          this.dataSource = new MatTableDataSource<PatientModel>(<PatientModel[]>(response.data.searchCernerPatients));
          this.noResultsMessage = '';
          return;
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  setPatientSearchParams(patientSearchParams: PatientSearchRequest) {
    this.isLoading = true;
    this.noResultsMessage = '';

    if (!patientSearchParams.isValidSearch && patientSearchParams.patientId == "") {
      this.isLoading = false;
      this.noResultsMessage = 'Try searching with a combination of at least two parameters.';
      return;
    }

    this.patientSearchParams = patientSearchParams;

    if (this.searchType == SEARCH_TYPE.MY_CARE) {
      this.store.dispatch(new UpdatePatientSearch(Object.assign({}, this.patientSearchParams)));
      this.getPatients(patientSearchParams);
    } else if (this.searchType == SEARCH_TYPE.CERNER) {
      this.store.dispatch(new UpdateCernerSearch(Object.assign({}, this.patientSearchParams)));
      this.getCernerPatients(patientSearchParams);
    }
  }

  goToViewUserProfile(username: string) {
    this.router.navigate(['/users/view', { user: username }]);
  }

  goToFacilitiesVisit(patientId: string) {
    this.router.navigate(['/facilitiesVisit', patientId]);
  }

  toPatientModelArray(patientsResult: PagedResult<Patient>): PatientModel[] {
    let patients: PatientModel[] = [];

    _.forEach(patientsResult, function (patientResult: any) {
      _.forEach(patientResult.userPatientRelationships, function (userPatient) {
        let patient: PatientModel = {};
        if (userPatient.user != null) {
          patient.username = userPatient.user.username;
        }
        patient.accountType = userPatient.relationshipType.toString().toUpperCase();
        patient.firstName = patientResult.firstName;
        patient.lastName = patientResult.lastName;
        patient.dateOfBirth = patientResult.dateOfBirth;
        patient.gender = patientResult.gender.toString().toUpperCase();

        patients.push(patient);
      });
    })

    return patients;
  }

  clear(evt:any): void {
    this.isLoading = false;
    if (this.dataSource) {
      this.dataSource.data = [];
    } else {
      this.dataSource = new MatTableDataSource<PatientModel>(<PatientModel[]>([]));
    }
    this.noResultsMessage = '';
    this.sortInfo.sortOrder = 'asc';
    this.sortInfo.sortColumn = '';
  }
}
