export const environment = {
    production: true,
    debug: '______IsDebugMode______',
    ciamUrl: '______CiamUrl______/api/',
    patientPortalsUrl: '______PatientPortalsUrl______/api/',    
    facilitiesUrl: '______FacilitiesUrl______/api/',
    ciamOktaClientId: '______OktaClientId______',
    idpId:'______OktaIdpId______',
    ciamOktaUrl: '______OktaUrl______',
    opsPortalApiUrl: '______OpsPortalApiUrl______',
    oktaAdminInterfaceUrl: '______OktaAdminInterfaceUrl______',
    providerSearchApiUrl: '______ProviderSearchApiUrl______/api/',
    facilitiesApimSubscriptionKey: '______FacilitiesApimSubscriptionKey______',
    facilitiesApimUrl:'______FacilitiesApimUrl______',
    useHash: true,
    enrollmentsErrorNumberOfDays: Number('______EnrollmentsErrorNumberOfDays______'),
    renewTimeBeforeTokenExpiresInSeconds:Number('______RenewTimeBeforeTokenExpiresInSeconds______'),
    featureFlags: {
        general: {
            allowAllUsers: bool('______FeatureFlags_General_AllowAllUsers______')
        },
        navigation: {
            termsAndConditions: {
                list: bool('______FeatureFlags_Navigation_TermsAndConditions_List______'),
                create: bool('______FeatureFlags_Navigation_TermsAndConditions_Create______'),
                view: bool('______FeatureFlags_Navigation_TermsAndConditions_View______')
            },
            instructions: {
              create: bool('______FeatureFlags_Navigation_Instructions_Create______')
            },
            facilities: {
                list: bool('______FeatureFlags_Navigation_Facilities_List______'),
                create: bool('______FeatureFlags_Navigation_Facilities_Create______')
            },
            patientPortals: {
                cerner: {
                    invitations: {
                        list: bool('______FeatureFlags_Navigation_PatientPortals_Cerner_Invitations_List______')
                    }
                }
            },
            logs: {
                search: bool('______FeatureFlags_Navigation_Logs_Search______'),
                enrollments: {
                  list: bool('______FeatureFlags_Navigation_Logs_Enrollments_List______'),
                  view: bool('______FeatureFlags_Navigation_Logs_Enrollments_View______')
                }
            },
            users: {
                search: bool('______FeatureFlags_Navigation_Users_List______'),
                view: bool('______FeatureFlags_Navigation_Users_View______')
            },
            barGroup: {
                list: bool('______FeatureFlags_Navigation_BarGroup_List______'),
                region: {
                    list: bool('______FeatureFlags_Navigation_BarGroup_Region_List______')
                },
                empihub: {
                    list: bool('______FeatureFlags_Navigation_BarGroup_EMPI_Hub_List______')

                },
                bargroupFacilities: {
                    create: bool('______FeatureFlags_Navigation_BarGroup_BargroupFacilities_Create______')
                }
          },
          azureSearch: {
            view: bool('______FeatureFlags_Navigation_AzureSearch_View______'),
            providerSearch: {
              view: bool('______FeatureFlags_Navigation_AzureSearch_ProviderSearch_View______')
            },
            scoreAndFields: {
              create: bool('______FeatureFlags_Navigation_AzureSearch_ScoreAndFields_Create______')
            }
          },
          siteSettings: {
            view: bool('______FeatureFlags_Navigation_SiteSettings_View______'),
            domainWhitelisting: {
              create: bool('______FeatureFlags_Navigation_SiteSettings_DomainWhitelisting_Create______')
            },
            alerts: {
              create: bool('______FeatureFlags_Navigation_SiteSettings_Alerts_Create______')
            }
          },
          facilitiesVisit: {
            view: bool('______FeatureFlags_Navigation_FacilitiesVisit_View______')
          },
          developersOptions:{
            view: bool('______FeatureFlags_Navigation_DevelopersOptions_View______'),
            cernerSSODashboard: {
              view: bool('______FeatureFlags_Navigation_DevelopersOptions_CernerSSODashboard_View______')
            }
          }         
        }
    }
};

function bool(expression: string): boolean {
    return expression == 'true';
}
