import { UserSearchRequest } from 'app/types/userSearchRequest';
import { PatientSearchRequest } from 'app/types/patientSearchRequest';

import { Action } from '@ngrx/store';

export const UPDATE_USER_SEARCH = "UPDATE_USER_SEARCH";
export class UpdateUserSearch implements Action {
  public type: string = UPDATE_USER_SEARCH;
  constructor(public payload: UserSearchRequest) { }
}

export const UPDATE_PATIENT_SEARCH = "UPDATE_PATIENT_SEARCH";
export class UpdatePatientSearch implements Action {
  public type: string = UPDATE_PATIENT_SEARCH;
  constructor(public payload: PatientSearchRequest) { }
}

export const UPDATE_CERNER_SEARCH = "UPDATE_CERNER_SEARCH";
export class UpdateCernerSearch implements Action {
  public type: string = UPDATE_CERNER_SEARCH;
  constructor(public payload: PatientSearchRequest) { }
}
