import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'app/types';
import { PatientSearchRequest } from 'app/types/patientSearchRequest';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE } from 'app/types/constants';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-patients-search',
  templateUrl: './patients-search.component.html',
  styleUrls: ['./patients-search.component.scss']
})

export class PatientsSearchComponent implements OnInit, OnChanges {

  @Input() searchType: string;
  @Input() hasResults = false;
  @Input() isLoading = false;
  @Output() patientSearchParams: EventEmitter<PatientSearchRequest> = new EventEmitter<PatientSearchRequest>();
  @Output() clearResult: EventEmitter<PatientSearchRequest> = new EventEmitter<PatientSearchRequest>();
  // Allowing any date but the year should be 4 digit
  minDate = MIN_DATE;
  maxDate = new Date();
  patientSearchForm: FormGroup;
  maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
  patientSearchRequest: PatientSearchRequest = <PatientSearchRequest>{
    patientId: '',
    mrn: '',
    lastName: '',
    firstName: '',
    dateOfBirth: null,
    isValidSearch: false
  };;
  @Input() patientSearchObject = this.patientSearchRequest;

  constructor(
    private fb: FormBuilder,
    private store: Store<IAppState>,
    private cd: ChangeDetectorRef
  ) {
    this.store.select(m => m.userSearch).subscribe(s => {
      if (!s || (!s.cernerSearch && !s.myCareSearch)) {
        return;
      }

      this.patientSearchRequest = s.myCareSearch != undefined ? Object.assign({}, s.myCareSearch) : Object.assign({}, s.cernerSearch);
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.resetPatientSearchParams();

    if (this.patientSearchRequest.isValidSearch == true) {
      this.patientSearchForm = this.fb.group({
        'patientId': [this.patientSearchRequest.patientId],
        'mrn': [this.patientSearchRequest.mrn],
        'lastName': [this.patientSearchRequest.lastName],
        'firstName': [this.patientSearchRequest.firstName],
        'dateOfBirth': [this.patientSearchRequest.dateOfBirth ? this.patientSearchRequest.dateOfBirth : null]
      });

      this.hasResults = true;
      this.patientSearchParams.emit(this.patientSearchRequest);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientSearchObject && this.patientSearchForm) {
      this.hasResults = false;
      const controls = this.patientSearchForm.controls;
      controls['patientId'].setValue(this.patientSearchObject.patientId);
      controls['mrn'].setValue(this.patientSearchObject.mrn);
      controls['lastName'].setValue(this.patientSearchObject.lastName);
      controls['firstName'].setValue(this.patientSearchObject.firstName);
      controls['dateOfBirth'].setValue(this.patientSearchObject.dateOfBirth);
    }
  }

  updateDobModel() {
    this.patientSearchForm.controls['dateOfBirth'].setValue(this.patientSearchForm.controls['dateOfBirth'].value);
  }

  modifySearch() {
    if (!this.patientSearchForm.valid) {
      return;
    }

    this.patientSearchObject.patientId = '';
    this.patientSearchObject.mrn = '';
    this.patientSearchObject.lastName = '';
    this.patientSearchObject.firstName = '';
    this.patientSearchObject.dateOfBirth = null;
    this.patientSearchObject.isValidSearch = false;

    const controls = this.patientSearchForm.controls;
    this.patientSearchObject.patientId = controls['patientId'].value;
    if (this.patientSearchObject.patientId == '') {
      this.patientSearchObject.mrn = controls['mrn'].value;
      this.patientSearchObject.lastName = controls['lastName'].value;
      this.patientSearchObject.firstName = controls['firstName'].value;
      this.patientSearchObject.dateOfBirth = controls['dateOfBirth'].value;
    }

    var filterArray = Object.keys(controls).map(function (personNamedIndex) {
      let p = controls[personNamedIndex];
      return p.value !== null && p.value != '';
    });

    // Ensure that length is greater than 1
    if (filterArray.filter((e) => {
      return e == true;
    }).length > 1) {
      this.patientSearchObject.isValidSearch = true;
    }

    this.hasResults = true;
    this.patientSearchParams.emit(this.patientSearchObject);
  }

  clear() {
    this.resetPatientSearchParams();
    const controls = this.patientSearchForm.controls;
    this.setPatientSearchValues(controls);
    this.patientSearchObject.isValidSearch = false;
    this.clearResult.emit(this.patientSearchObject);
    this.hasResults = false;
  }

  setPatientSearchValues(controls) {
    controls['patientId'].value = '';
    controls['firstName'].value = '';
    controls['lastName'].value = '';
    controls['mrn'].value = '';
    controls['dateOfBirth'].value = null;
  }

  resetPatientSearchParams() {
    if (this.patientSearchForm) {
      this.patientSearchForm.reset();
    } else {
      this.patientSearchForm = this.fb.group({
        'patientId': [''],
        'mrn': [''],
        'lastName': [''],
        'firstName': [''],
        'dateOfBirth': [null]
      });
    }
  }
}
