import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CiamPersonGender, CiamPersonIdLevel } from '@dignity-health/ciam-auth';
import { IAppState } from 'app/types';
import { SEARCH_TYPE } from 'app/shared/enums/enums';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE } from 'app/types/constants';
import { UiService } from '../../../services/ui/ui.service';
import { UserSearchRequest } from '../../../types/userSearchRequest';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-users-search',
  templateUrl: './users-search.component.html',
  styleUrls: ['./users-search.component.scss']
})

export class UsersSearchComponent implements OnInit {
  basicUserSearchText: string;
  minDate = MIN_DATE;
  maxDate = new Date();
  genders = CiamPersonGender;
  levels = CiamPersonIdLevel;
  advancedSearchForm: FormGroup;
  searchType = SEARCH_TYPE.MY_HOME;
  maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
  showAdvancedSearch: boolean = true;
  @Input() hasResults;
  @Output() advancedSearchParams: EventEmitter<UserSearchRequest> = new EventEmitter<UserSearchRequest>();

  advancedSearchObject: UserSearchRequest = <UserSearchRequest>{
    username: '',
    firstName: '',
    lastName: '',
    dob: null,
    gender: null,
    userLevel: null,
    createdDate: null,
    inQuickerConfirmationNumber: '',
    isAdvancedUserSearch: false,
    basicUserSearchText: ''
  };

  constructor(
    private fb: FormBuilder,
    private uiService: UiService,
    private store: Store<IAppState>
  ) {
    this.store.select(m => m.userSearch).subscribe(s => {
      if (!s || !s.myHomeSearch) {
        return;
      }

      this.advancedSearchObject = Object.assign({}, s.myHomeSearch);
      this.advancedSearchObject.dob = s.myHomeSearch.dob ? new Date(s.myHomeSearch.dob) : null;
      this.advancedSearchObject.createdDate = s.myHomeSearch.createdDate ? new Date(s.myHomeSearch.createdDate) : null;
    });
  }

  ngOnInit() {
    this.resetAdvancedSearchParams();

    if ((this.advancedSearchObject.basicUserSearchText != "" && this.advancedSearchObject.basicUserSearchText != undefined)
      || this.advancedSearchObject.isAdvancedUserSearch) {
      this.showAdvancedSearch = true;
      this.basicUserSearchText = this.advancedSearchObject.basicUserSearchText;
      this.advancedSearchForm = this.fb.group({
        'username': [this.advancedSearchObject.username],
        'lastName': [this.advancedSearchObject.lastName],
        'firstName': [this.advancedSearchObject.firstName],
        'dob': [this.advancedSearchObject.dob ? new Date(this.advancedSearchObject.dob) : null],
        'gender': [this.advancedSearchObject.gender],
        'userLevel': [this.advancedSearchObject.userLevel],
        'createdDate': [this.advancedSearchObject.createdDate ? new Date(this.advancedSearchObject.createdDate) : null],
        'inQuickerConfirmationNumber': [this.advancedSearchObject.inQuickerConfirmationNumber]
      });

      this.advancedSearchParams.emit(this.advancedSearchObject);
    }
  }

  modifySearch() {
    if (!this.advancedSearchForm.valid) {
      return;
    }

    var formValuesCount = 0;
    Object.keys(this.advancedSearchForm.value)
      .map(key => this.advancedSearchForm.value[key])
      .forEach(x => {
        if (x != null && x != "") {
          formValuesCount++;
        }
      });

    this.advancedSearchObject.basicUserSearchText = this.basicUserSearchText;
    if (this.showAdvancedSearch && formValuesCount > 0) {
      const controls = this.advancedSearchForm.controls;
      this.advancedSearchObject.gender = controls['gender'].value;
      this.advancedSearchObject.username = controls['username'].value;
      this.advancedSearchObject.lastName = controls['lastName'].value;
      this.advancedSearchObject.firstName = controls['firstName'].value;
      this.advancedSearchObject.userLevel = controls['userLevel'].value;
      this.advancedSearchObject.dob = this.uiService.formatDate(controls['dob'].value);
      this.advancedSearchObject.createdDate = this.uiService.formatDate(controls['createdDate'].value);
      this.advancedSearchObject.inQuickerConfirmationNumber = controls['inQuickerConfirmationNumber'].value;
      this.advancedSearchObject.isAdvancedUserSearch = true;
    } else {
      this.resetadvancedSearchObject(this.basicUserSearchText);
    }

    this.advancedSearchParams.emit(this.advancedSearchObject);
  }

  clearSearch() {
    this.resetAdvancedSearchParams();
    this.basicUserSearchText = '';
    this.resetadvancedSearchObject(this.basicUserSearchText);
    this.advancedSearchParams.emit(this.advancedSearchObject);
  }

  toggleAdvancedSearchControls() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    this.advancedSearchObject.isAdvancedUserSearch = this.showAdvancedSearch;
    if (!this.showAdvancedSearch) {
      this.resetAdvancedSearchParams();
    }
  }

  updateDobModel() {
    this.advancedSearchForm.controls['dob'].setValue(this.advancedSearchForm.controls['dob'].value);
  }

  updateCreatedDateModel() {
    this.advancedSearchForm.controls['createdDate'].setValue(this.advancedSearchForm.controls['createdDate'].value);
  }

  resetAdvancedSearchParams(): void {
    if (this.advancedSearchForm) {
      this.advancedSearchForm.reset();
    } else {
      this.advancedSearchForm = this.fb.group({
        'username': [''],
        'lastName': [''],
        'firstName': [''],
        'dob': [null],
        'gender': [null],
        'userLevel': [null],
        'createdDate': [null],
        'inQuickerConfirmationNumber': ['']
      });
    }
  }

  resetadvancedSearchObject(basicSearchText: string): void {
    this.advancedSearchObject = {
      username: '',
      firstName: '',
      lastName: '',
      dob: null,
      gender: null,
      userLevel: null,
      createdDate: null,
      inQuickerConfirmationNumber: '',
      isAdvancedUserSearch: false,
      basicUserSearchText: basicSearchText
    };
  }

  searchTypeChanged($event) {
    this.searchType = $event.value;
  }
}
