import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-status-dialog',
    templateUrl: 'temporary-user-password-status.component.html',
})

export class TemporaryUserPasswordStatusComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: TemporaryUserPasswordStatus) { }
}

export interface TemporaryUserPasswordStatus {
    title: string;
    message: string;
    password: string;
}
