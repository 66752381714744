import { CiamAuth, LogLevel } from '@dignity-health/ciam-auth';
import { environment } from '../environments/environment';

export function ciamAuthFactory(): CiamAuth {
  return new CiamAuth({   
    services: {
      ciam: {
        apiUrl: environment.ciamUrl,
      },
      patientPortals: {
        apiUrl: environment.patientPortalsUrl,
      },     
      facilities: {
        apiUrl: environment.facilitiesApimUrl,
        apimSubscriptionKey:  environment.facilitiesApimSubscriptionKey,
      },
      providerSearch: {
        apiUrl: environment.providerSearchApiUrl
      }
    },  
    environment: '',
    oktaUrl: environment.ciamOktaUrl,
    clientId: environment.ciamOktaClientId,oktaAuthServerId:environment.ciamOktaClientId,
    common: {
      logLevel: LogLevel.Debug,
      urlSetter: url => console.log(`[CiamAuthFactory]: Tried to navigate to ${url}`)
    },
    httpOptions: {
      useServiceResponses: true
    },
    sessionOptions: {
      useApiForLogin: true,
      sessionDurationSeconds: 5000,
      timeoutWarningSeconds: 20
    }
  })
}
