import { BehaviorSubject } from 'rxjs';
import { ITermsAndConditions } from '@dignity-health/ciam-auth';
import { Injectable, InjectionToken } from '@angular/core';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import 'rxjs';

import * as _ from 'lodash';

export const TOKEN = new InjectionToken("terms-and-condtions-datasource");

@Injectable()
export class TermsAndConditionsDataSource extends MatTableDataSource<ITermsAndConditions> {
  private static readonly DEFAULT_PROJECTION = 'id text version isActive';
  public isLoading = new BehaviorSubject<boolean>(true);
    constructor(private ciamAuth: CiamAuth) {
        super();
    }

  connect(): BehaviorSubject<ITermsAndConditions[]> {
    this.setIsLoading(true);
    const subject = new BehaviorSubject<ITermsAndConditions[]>([]);
    this.ciamAuth.httpApi.apiInfoQueryPost({
            query: `{ termsAndConditions(getCompleteTermsText: false) 
                { ${TermsAndConditionsDataSource.DEFAULT_PROJECTION} } }`
    }, true)
            .then(res => _.orderBy(res.data.termsAndConditions, [t => t.id], ['desc']))
            .then(terms => {
                subject.next(<ITermsAndConditions[]>terms);
              subject.complete();
              this.setIsLoading(false);
            });
        return subject;
    }
  disconnect(): void { }

  setIsLoading(val: boolean) {
    this.isLoading.next(val);
  }
}
