import { OnInit, Component, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/types';
import { CiamUser, CiamAuth, PatientEnrollment } from "@dignity-health/ciam-auth";
import { UiService } from 'app/services/ui/ui.service';
import { PagedResult, GraphQLQuery } from '../../../types/graphql';
import { PatientEnrollmentSearchRequest } from "../../../types/patient-enrollment-search-request";
import { PageInfo, SortInfo } from "../../../types/models";
import { environment } from "environments/environment";
import { Observable } from 'rxjs';

const GET_PATIENT_ENROLLMENTS_QUERY = `
query getPatientEnrollments(
  $pageInfo: PageInfo,
  $sortInfo: SortInfo,
  $searchRequest: PatientEnrollmentSearchRequest){ 
    searchPatientEnrollments( 
      pageInfo: $pageInfo,
      sortInfo: $sortInfo,
      searchRequest: $searchRequest)
    {
      pageSize
      currentPage
      pageCount
      rowCount
      results {
        enrollmentId
        userId        
        portalName       
        status
        startDateTime
        endDateTime  
        patientEnrollmentType
        user {          
          username         
          self {
            firstName
            lastName            
          }          
        }
      }
    }  
  }`;

@Component({
  selector: 'app-patient-enrollments',
  templateUrl: './patient-enrollments.component.html',
  styleUrls: ['./patient-enrollments.component.scss']
})
export class PatientEnrollmentsComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  isLoading: boolean = false;
  hasResults: boolean = false;
  emptyUsername: boolean = false;

  patientEnrollmentsSearchParams: PatientEnrollmentSearchRequest;

  dataSource: MatTableDataSource<PatientEnrollment>;

  @ViewChild('table')
  table: MatTable<PatientEnrollment>;

  sortInfo: SortInfo = { sortColumn: "startDateTime", sortOrder: "desc" };
  displayedColumns = ["userName", "firstName", "lastName", "portal", "status", "type", "startDateTime", "actions"];

  @ViewChild('paginator')
  paginator: MatPaginator;

  currentPage: number = 1;
  pageSize: number = 10;
  rowCount: number = 0;
  pageSizeOptions: number[] = [5, 10, 20];
  pagedResult: PagedResult<PatientEnrollment[]>;

  constructor(
    private store: Store<IAppState>,
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.getPatientEnrollments();
  }

  async getPatientEnrollments(): Promise<void> {
    this.isLoading = true;
    this.hasResults = false;

    const patientEnrollmentQuery: GraphQLQuery = {
      query: GET_PATIENT_ENROLLMENTS_QUERY,
      variables: this.makePatientEnrollmentGraphQlVariables()
    }

    const result = await this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(patientEnrollmentQuery);
    const patientEnrollment = result && result.data && result.data.searchPatientEnrollments && result.data.searchPatientEnrollments.results;

    this.isLoading = false;
    if (!patientEnrollment) {
      return this.uiService.showErrors(result.errors);
    }
    
    this.emptyUsername = patientEnrollment.filter((enrollment,i, arr)=> arr.findIndex(x => x.user  === null) === i).length>0;

    this.dataSource = new MatTableDataSource(patientEnrollment);    
    this.rowCount = result.data.searchPatientEnrollments.rowCount;
    this.pageSize = result.data.searchPatientEnrollments.pageSize;
    this.currentPage = result.data.searchPatientEnrollments.currentPage;

    this.isLoading = false;
    this.hasResults = this.rowCount > 0;
  }

  onPaginationChange(pageEvent: PageEvent) {
    this.pageSize = pageEvent == null ? this.pageSize : pageEvent.pageSize;
    this.currentPage = pageEvent == null ? this.currentPage : pageEvent.pageIndex + 1;    
    this.getPatientEnrollments();
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.sortInfo = { sortColumn: sort.active, sortOrder: sort.direction };
    this.getPatientEnrollments();
  }

  setPatientEnrollmentsSearchParams(patientEnrollmentsSearchParams: PatientEnrollmentSearchRequest) {
    this.paginator.pageIndex = 0;
    this.currentPage = 1;
    this.patientEnrollmentsSearchParams = patientEnrollmentsSearchParams;
    this.getPatientEnrollments();
  }

  makePatientEnrollmentGraphQlVariables(): PatientEnrollmentVariables {
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - environment.enrollmentsErrorNumberOfDays);
    return {
      searchRequest: {
        portalName: !this.patientEnrollmentsSearchParams ? "" : this.patientEnrollmentsSearchParams.portalName,
        userName: !this.patientEnrollmentsSearchParams ? "" : this.patientEnrollmentsSearchParams.userName,
        status: !this.patientEnrollmentsSearchParams ? null : this.patientEnrollmentsSearchParams.status,
        patientEnrollmentType: !this.patientEnrollmentsSearchParams ? null : this.patientEnrollmentsSearchParams.patientEnrollmentType,
        startDateTime: !this.patientEnrollmentsSearchParams ? fromDate : this.patientEnrollmentsSearchParams.startDateTime,
        endDateTime: !this.patientEnrollmentsSearchParams ? new Date() : this.patientEnrollmentsSearchParams.endDateTime
      },
      pageInfo: {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      },
      sortInfo: {
        sortColumn: this.sortInfo.sortColumn,
        sortOrder: this.sortInfo.sortOrder
      }
    }
  }

  showErrorDetail(enrollmentId: number) {
    this.router.navigate(['patient/enrollment-errors', enrollmentId]);
  }
}

interface PatientEnrollmentVariables {
  pageInfo: PageInfo,
  sortInfo: SortInfo,
  searchRequest: PatientEnrollmentSearchRequest
}
