<div class="outerDiv">
    <div class="contentDiv">
        A temporary password will be generated for customer who does not know the answer to their Security
        Question. The temporary password must be given to the customer on the phone call. After the customer logs
        in with this temporary password, they will be asked to set a new password. Please suggest the change their
        security question and answer also.
    </div>
    <div class="actionDiv">
        <action-confirmation class="float-right" [actionConfirmation]="temporaryPasswordActionConfirmationData"
            (userResponse)="temporaryPasswordResponse($event)">
        </action-confirmation>
        <mat-spinner *ngIf="showTemporaryPasswordSpinner" class="float-left" [diameter]="30"></mat-spinner>
    </div>
</div>