import { Component } from '@angular/core';
import { InstructionRequest, CiamAuth } from '@dignity-health/ciam-auth';
import { UiService } from '../../services/ui/ui.service';
import { GraphQLQuery } from '../../types/graphql';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationErrors, ValidationMessages } from '../../types/forms';
import { CiamUser } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { IAppState } from '../../types/state';
import { InstructionService } from 'app/services/instructions/instructions-service';
import { Observable } from 'rxjs';

const validationMessages = {
  description: {
    'required': 'description is required'
  }
}

@Component({
  selector: 'instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})

export class InstructionsComponent {
  form: FormGroup;
  showLoader: boolean = false;
  getInstructionQuery: GraphQLQuery;
  instruction: InstructionRequest;
  description = new FormControl('', [Validators.required]);
  title = new FormControl();
  validationErrors: ValidationErrors = {};
  user: CiamUser;
  user$: Observable<CiamUser>;

  constructor(
    private fb: FormBuilder,
    public ciamAuth: CiamAuth,
    private uiService: UiService,
    private instructionService: InstructionService,
    private store: Store<IAppState>,
  ) {
    this.user$ = store.select(s => s.user)
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.form = this.fb.group({
      'description': [''],
      'title': ['']
    });
    this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data));
    this.getInstruction();
  }

  onFormValuesChanged(data: any) { }

  async getInstruction() {
    this.showLoader = true;
    this.instruction = await this.instructionService.getMyHelpInstructions();

    if (this.instruction) {
      this.form = this.fb.group({
        description: this.description,
        title: this.title
      });

      this.title.setValue(this.instruction.title);
      this.description.setValue(this.instruction.description);
    }

    this.showLoader = false;
  }

  saveInstruction() {
    this.showLoader = true;
    if (!this.form.valid) {
      this.uiService.openSnackbar("Please enter valid details.", null, { duration: 2500 });
      this.showLoader = false;

      return;
    }
    var instructionDescription = this.form.controls["description"].value;
    if (this.instruction) {
      this.updateInstruction(instructionDescription);
    }
  }

  private updateInstruction(instructionDescription: string) {
    this.instruction.description = instructionDescription;

    this.ciamAuth.httpApi.apiAdminInstructionByInstructionidPatch(this.instruction, this.instruction.instructionId.toString())
      .then(response => {

        this.showLoader = false;

        if (response.isValid) {
          this.uiService.openSnackbar('instruction successfully updated', 'Success');
          return;
        }

        this.uiService.openSnackbar(response.errors[0].message, 'Error');
      });
  }
}
