<div  *ngIf="selectedUser">
  <mat-card>
    <mat-card-title>User Created</mat-card-title>
     <mat-card-content>
      {{selectedUser.createdDate | date: "MMMM d, y"}}
    </mat-card-content>
  </mat-card>
  
  <mat-card *ngIf="hasEditAccess">
    <mat-card-title>User Level</mat-card-title>
     <mat-card-content>
      <mat-spinner *ngIf="isULLoading" [diameter]="30"></mat-spinner>
      <form [formGroup]="form" novalidate (ngSubmit)="setUserLevel()" class="padding-top" *ngIf="!isULLoading">
        <mat-form-field flex="">
          <mat-select formControlName="userLevel" placeholder="User Level" (selectionChange) = "onULChange($event)" [disabled]="isULLoading">
            <mat-option *ngFor="let level of levels | enumKeyValueList;" [value]="level.key" [disabled]="level.key == 'Level1'|| level.key == 'Unknown'"> {{level.key}} </mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button class="submit-button" mat-raised-button color="primary" type="submit" *ngIf="needToSaveUserLevel"> Update User Level </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Terms and Conditions</mat-card-title>
    <mat-card-content>
      Status - {{ termsAccepted ?  "Accepted" : "Not Accepted" }}
      <div> 
        <mat-spinner *ngIf="isTCLoading" [diameter]="30"></mat-spinner>
        <mat-slide-toggle *ngIf="!isTCLoading && hasEditAccess" (change) = "onTCChange($event);" [(ngModel)] = "termsAccepted"></mat-slide-toggle>      
      </div>    
      <div *ngIf="needToSaveTerms">
        <button *ngIf="!isTCLoading" class="submit-button" mat-raised-button color="primary" type="submit" (click)="onSaveTerms();"> Update Terms and Conditions </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Email Validation</mat-card-title>
    <mat-card-content>
      Status - {{ emailVerified ?  "Accepted" : "Not Accepted" }}
      <div> 
        <mat-spinner *ngIf="isEVLoading" [diameter]="30"></mat-spinner>
        <mat-slide-toggle *ngIf="!isEVLoading && !this.selectedUser.isVerified && hasEditAccess" (change) = "onEVChange($event);" [(ngModel)] = "emailVerified"></mat-slide-toggle>
      </div>
      <div *ngIf="needToSaveEmailVerified && !isEVLoading">
        <button class="submit-button" mat-raised-button color="primary" type="submit" (click)="onEmailValidationSave();"> Update Email Validation </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>DHome ID (Self ID)</mat-card-title>
     <mat-card-content>
      {{selectedUser.selfId}}
    </mat-card-content>
  </mat-card>
</div>