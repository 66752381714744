import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { CreateFacilityRequest } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../types';
import { UiService } from '../../../services/ui/ui.service';
import { FacilitiesService } from '../../../services/facilities/facilities-service';
import { FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { FacilitiesCreateUpdateComponent } from 'app/components/facility/facilities-create-update/facilities-create-update.component';

@Component({
  selector: 'facilities-create',
  templateUrl: './facilities-create.component.html',
  styleUrls: ['./facilities-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacilitiesCreateComponent {
  createRequest: CreateFacilityRequest;
  @ViewChild("facilitiesCreateUpdateComponent") child: FacilitiesCreateUpdateComponent;

  constructor(
    private store: Store<IAppState>,
    private uiService: UiService,
    private facilitiesService: FacilitiesService,
    private router: Router,
    private fb: FormBuilder) {

    // init the complex properties
    this.createRequest = {
      facilityDomains: [],
      alternateFacilityNames: [],
      address: {}
    };
  }

  async onClickSave() {
    try {
      if (!this.child.checkDomainFields()) {
        return;
      }

      let result = await this.facilitiesService.createFacility(this.createRequest);
      if (!result.isValid) {
        return this.uiService.showErrors(result.errors);
      }

      this.router.navigateByUrl('facilities');
    } catch (e) {
      this.uiService.showException(e);
    }
  }
}
