<div class="page-content app-login-margin" fxLayout="column" fxLayoutAlign="center center">
    <div class="root-card">
        <mat-spinner *ngIf="showSpinner"></mat-spinner>
        <mat-card class="mat-elevation-z6" *ngIf="showSpinner == false">
            <mat-card-content>
                <button mat-button color="primary" style="width: 100%;" mat-raised-button type="submit" (click)="login()">Login With Okta</button>
            </mat-card-content>
        </mat-card>
        <div class="margin-top">
            <mat-card class="mat-elevation-z6" *ngIf="showSpinner == false">
                <span class="title-font">Instructions</span>
                <mat-card-subtitle>
                    {{ description }}
                </mat-card-subtitle>
                <mat-card-content class="need-access">
                    <button mat-button color="primary" (click)="needAccess()">Need Access</button>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>