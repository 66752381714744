<ng-template mat-tab-label>
    <mat-icon>person</mat-icon> Profile
</ng-template>
<mat-card>
    <mat-card-content>
        <form (ngSubmit)="updateUserProfile()" novalidate [formGroup]="form">
            <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                <div fxFlex>
                    <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                        <mat-card-title fxFlex="80%">User Profile</mat-card-title>
                    </div>
                    <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                        <form-input [validationErrors]="validationErrors" [name]="'firstName'" fxFlex>
                            <mat-form-field>
                                <input matInput type="text" placeholder="First Name" formControlName="firstName" [readonly]="!isMyHomeUser" />
                                <mat-error *ngIf="firstName.hasError('required')">Please enter First Name</mat-error>
                            </mat-form-field>
                        </form-input>
                        <form-input [validationErrors]="validationErrors" [name]="'lastName'" fxFlex>
                            <mat-form-field>
                                <input matInput type="text" placeholder="Last Name" formControlName="lastName" [readonly]="!isMyHomeUser" />
                                <mat-error *ngIf="lastName.hasError('required')">Please enter Last Name</mat-error>
                            </mat-form-field>
                        </form-input>
                    </div>
                    <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                        <form-input [validationErrors]="validationErrors" [name]="'email'" fxFlex="">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Email" formControlName="email" [readonly]="true" />
                                <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </form-input>
                        <form-input [validationErrors]="validationErrors" [name]="'phone'" fxFlex="">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Phone" formControlName="phoneNumber" [readonly]="!isMyHomeUser" />
                            </mat-form-field>
                        </form-input>
                    </div>
                </div>
            </div>
            <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                <form-input [validationErrors]="validationErrors" [name]="'dateOfBirth'" fxFlex="">
                    <mat-form-field>
                        <input matInput [matDatepicker]="dateOfBirth" placeholder="Date Of Birth" formControlName="dateOfBirth" [readonly]="!isMyHomeUser || isLevel4User" />
                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth" [disabled]="!isMyHomeUser || isLevel4User">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dateOfBirth></mat-datepicker>
                    </mat-form-field>
                </form-input>
                <form-input [validationErrors]="validationErrors" [name]="'gender'" fxFlex="">
                    <mat-form-field>
                        <mat-select placeholder="Gender" formControlName="gender">
                            <mat-option *ngFor="let gender of genders | enumKeyValueList;" [value]="gender.value" [disabled]="!isMyHomeUser">
                                {{ gender.key }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="gender.hasError('required')">Please select gender</mat-error>
                    </mat-form-field>
                </form-input>
            </div>
            <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                <form-input [validationErrors]="validationErrors" [name]="'address line 1'" fxFlex="">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Address Line 1" formControlName="addressLine1" [readonly]="!isMyHomeUser" />
                    </mat-form-field>
                </form-input>
                <form-input [validationErrors]="validationErrors" [name]="'address line 2'" fxFlex="">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Address Line 2" formControlName="addressLine2" [readonly]="!isMyHomeUser" />
                    </mat-form-field>
                </form-input>
            </div>
            <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                <form-input [validationErrors]="validationErrors" [name]="'city'" fxFlex="">
                    <mat-form-field>
                        <input matInput type="text" placeholder="City" formControlName="city" [readonly]="!isMyHomeUser" />
                    </mat-form-field>
                </form-input>
                <form-input [validationErrors]="validationErrors" [name]="'stateOrProvince'" fxFlex="">
                    <mat-form-field>
                        <input matInput type="text" placeholder="State" formControlName="stateOrProvince" [readonly]="!isMyHomeUser" />
                    </mat-form-field>
                </form-input>
                <form-input [validationErrors]="validationErrors" [name]="'zipCode'" fxFlex="">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Zip Code" formControlName="zipCode" [readonly]="!isMyHomeUser" />
                    </mat-form-field>
                </form-input>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>

            <div fxlayout="row" style="margin-top:10px;" fxLayout.lt-md="column" fxLayoutGap="1em" *ngIf="canUpdateUserProfile">
                <button mat-raised-button color="primary" type="submit" [disabled]="!isMyHomeUser">Update User
                    Profile</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>