import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { IAppState } from 'app/types';
import { GraphQLQuery } from '../../../types/graphql';
import { UiService } from 'app/services/ui/ui.service';
import { CiamAuth, CiamUser, UserPatientRelationship, UserPatientRelationshipRelationshipType, QueryPatientByPersonRequest } from '@dignity-health/ciam-auth';

const GET_DEPENDENTS_BY_USERNAME_QUERY = `
    query getAssociatesByUserName($username : String)
    {
      users(username: $username)
      {
        firstName,
        lastName,
        dependents {
            relationshipType,
            userPersonAccessId,
            personId,            
            person
            {
              firstName,
              email,
              lastName,
              dateOfBirth,
              gender,
              primaryCaregiverName,
              contactName,
              contactPhoneNumber
              address {
                line1
                line2
                zipCode
                city
        				stateOrProvince
              }            
            }
        }
      }
  }`;

const GET_AUTH_REPRESENTATIVES = `
  query getAuthorizedRepresentatives($userId: Int, $patientSearchParams: QueryPatientByPersonRequest)
  {
    user
    {   
      authorizedRepresentative(userId:$userId, patientSearchParams: $patientSearchParams)
      {
        userPatientRelationshipId
        userId,
        patientId,
        relationshipType,
        user{
          email: username
          userId
          self
          {
            firstName
            lastName
            dateOfBirth
            gender
            idLevel
          }
        }    
      }
    }
}`;

const GET_PATIENT_DEPENDENTS_BY_USERNAME_QUERY = `
  query getPatientDependentsByUserName($username : String){
  users(username: $username) {    
    patients {
      userId
      userPatientRelationshipId
      relationshipType
      isPatientUserExist
      user {
        selfId
        username
      }
      isActive
      patient {
        patientId
        firstName
        dateOfBirth
        lastName
        sourceId
        gender
        domain {
          name
          portal {
            portalId
            name            
          }
        }
        address{
          stateOrProvince
          line1
          city
          zipCode
        }        
      }
    }
  }
}`;

@Component({
    selector: 'app-user-associated-account-profile',
    templateUrl: './user-associated-account-profile.component.html',
    styleUrls: ['./user-associated-account-profile.component.scss']
})

export class UserAssociatedAccountProfileComponent implements OnInit {
    @Input()
    selectedUser: CiamUser;

    @Input()
    isMyHomeUser: boolean;

    @Input()
    parentUserId: number;

    userAuthRepresentatives: UserPatientRelationship[] = [];
    userDependents: any[] = [];
    patientDependents: UserPatientRelationship[] = [];
    filteredUserDependents: any[] = [];

    userDependentsQuery: GraphQLQuery;
    userAuthRepresentativesQuery: GraphQLQuery;
    patientDependentsQuery: GraphQLQuery;

    userId: number;
    patientId: string;
    username: string;
    isValid: boolean = false;

    constructor(
        private ciamAuth: CiamAuth,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<IAppState>,
        public snackBar: MatSnackBar,
        private uiService: UiService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        const myCareIcon = "../../assets/icons/DH-MyCare.svg";
        const myCareNowIcon = "../../assets/icons/DH-MyCareNow.svg";
        const myCareGoldIcon = "../../assets/icons/DH-MyCare-gold.svg";

        this.matIconRegistry.addSvgIcon(
            `MyCareIcon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(myCareIcon)
        );
        this.matIconRegistry.addSvgIcon(
            `MyCareNowIcon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(myCareNowIcon)
        );
        this.matIconRegistry.addSvgIcon(
            `MyCareGoldIcon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(myCareGoldIcon)
        );
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.username = params['user'];
            this.patientId = params['patientId'];
        });

        this.username = this.selectedUser.email;
        this.userId = this.selectedUser.userId;

        //We don't need to show dependents for a non-my-home-user
        if (this.username != null) {
            this.getUserDependents();
        }

        this.getUserAuthRepresentatives();
    }

    async getUserDependents(): Promise<void> {
        this.userDependentsQuery = {
            query: GET_DEPENDENTS_BY_USERNAME_QUERY,
            variables: {
                'username': this.username
            }
        };

        const result = await this.ciamAuth.httpApi.apiUsersQueryPost(this.userDependentsQuery, true);
        this.userDependents = result && result.data && result.data.users && result.data.users.dependents;

        this.getPatientDependents();

        if (!this.userDependents) {
            return this.uiService.showErrors(result.errors)
        }

        this.isValid = true;
    }

    async getUserAuthRepresentatives(): Promise<void> {
        this.userAuthRepresentativesQuery = {
            query: GET_AUTH_REPRESENTATIVES,
            variables: this.makeAuthRepresentativesVariables()
        }

        const result = await this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.userAuthRepresentativesQuery);
        this.userAuthRepresentatives = result && result.data && result.data.user && result.data.user.authorizedRepresentative;
        if (!this.userAuthRepresentatives) {
            return this.uiService.showErrors(result.errors)
        }

        this.isValid = true;
    }

    async getPatientDependents(): Promise<void> {
        this.patientDependentsQuery = {
            query: GET_PATIENT_DEPENDENTS_BY_USERNAME_QUERY,
            variables: {
                'username': this.username
            }
        };

        const result = await this.ciamAuth.httpApi.apiUsersQueryPost(this.patientDependentsQuery, true);

        var patientDependents: UserPatientRelationship[] = result && result.data && result.data.users && result.data.users.patients;
        if (patientDependents) {
            var filteredPatientDependents: UserPatientRelationship[] = patientDependents.filter(p => p.relationshipType.toString().toLowerCase() == UserPatientRelationshipRelationshipType.Dependent.toString().toLowerCase());
            this.getDependents(filteredPatientDependents);
        } else {
            this.filteredUserDependents = this.userDependents;
        }

        if (!this.patientDependents) {
            return this.uiService.showErrors(result.errors);
        }
        else {
            this.patientDependents = this.patientDependents.filter(p => p.isActive);
        }
        this.isValid = true;
    }

    private getDependents(patientDependents: UserPatientRelationship[]): void {
        this.patientDependents = patientDependents;
        //Need to remove duplicate dependents that we get from UserPersonAccess, instead we will show dependents from UserPatientRelationship
        patientDependents.forEach((obj) => {
            const duplicateRecordIndex = this.userDependents.findIndex(u => u.relationshipType === 'dependent' &&
                u.person.firstName === obj.patient.firstName &&
                u.person.lastName === obj.patient.lastName && u.person.dateOfBirth === obj.patient.dateOfBirth);
            if (duplicateRecordIndex !== -1) {
                obj.patientPersonId = this.userDependents[duplicateRecordIndex].personId;
                obj.personAccess = new Array();
                obj.personAccess.push(this.userDependents[duplicateRecordIndex]);
                this.userDependents.splice(duplicateRecordIndex, 1);
            }
        });
        this.filteredUserDependents = this.userDependents;
    }

    async deleteAssociatedAccount(userPersonAccessId: number): Promise<void> {
        if (!confirm('Are you sure want to remove the Associated Account?')) {
            return;
        }

        const result = await this.ciamAuth.httpApi.apiAdminUsersRemoveDependentByUserPersonAccessIdDelete(userPersonAccessId)
        if (!result) {
            return this.uiService.showErrors(result.errors)
        }

        this.snackBar.open("Removed Sucessfully", null, { duration: 2500 });
        this.getUserDependents();
        this.getUserAuthRepresentatives();
    }

    async onAuthRepSeverAccessSuccess(isSuccess: boolean, userPatientRelationship: UserPatientRelationship) {
        if (!isSuccess) {
            return;
        }
        this.userAuthRepresentatives.splice(this.userAuthRepresentatives.indexOf(userPatientRelationship), 1);
    }

    async onPatientDependentSeverAccessSuccess(isSuccess: boolean, userPatientRelationship: UserPatientRelationship) {
        if (!isSuccess) {
            return;
        }
        this.patientDependents.splice(this.patientDependents.indexOf(userPatientRelationship), 1);
        if (this.patientDependents == null) {
            this.getUserDependents();
        }
    }

    makeAuthRepresentativesVariables(): AuthRepresentativesVariables {
        return {
            userId: this.isMyHomeUser ? this.userId : this.parentUserId,
            patientId: this.patientId,
            patientSearchParams: this.isMyHomeUser
                ? null
                : {
                    firstName: this.selectedUser.firstName,
                    lastName: this.selectedUser.lastName,
                    dateOfBirth: this.selectedUser.dateOfBirth
                }

        }
    }
}

interface AuthRepresentativesVariables {
    userId: number,
    patientId: string,
    patientSearchParams: QueryPatientByPersonRequest
}
